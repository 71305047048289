import React from "react";

import { useTheme, useMediaQuery, Grid, Box, Typography } from "@mui/material";
import { FooterImage, FooterTextLink } from "./styles/Footer.styles";
import GooglePlayIcon from '../assets/google-play-icon.webp';
import AppleAppStoreIcon from '../assets/app-store-icon.webp';

const Footer = () => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const currentDate = new Date();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={(theme) => ({
        backgroundColor: "#232426",
        alignItems: "center",
        padding: "20px 0px 0 0",
        marginTop: 0,
        [theme.breakpoints.down("sm")]: {
          height: "auto",
          padding: "20px 20px",
          // flexDirection: "column-reverse",
        },
      })}
      // className={classes.root}
      spacing={3}
    >
      <Grid item>
        <a href="https://apps.apple.com/us/app/myvity/id1582812756" target="_blank" rel="noreferrer">
          <FooterImage
            height={48}
            src={AppleAppStoreIcon}
          />
        </a>
        <a href="https://play.google.com/store/apps/details?id=sumit5ue.MyVity" target="_blank" rel="noreferrer" style={{ paddingLeft: "10px" }}>
          <FooterImage
            height={48}
            src={GooglePlayIcon}
          />
        </a>
      </Grid>

      <Grid item sx={{ mt: "40px" }}>
        <Grid container justifyContent="space-between" spacing={2}>
          {/* <Grid item>
            <Link to="/about-us" > <Box sx={{ color: "#fff", fontSize: "18px", fontFamily: "Calibre-Regular" }}>
              About Us</Box>
            </Link>
          </Grid> */}
          <Grid item>
            <FooterTextLink href="/privacy-policy.pdf" target="_blank">
              {" "}
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "18px",
                }}
              >
                Privacy Policy
              </Typography>
            </FooterTextLink>
          </Grid>
          <Grid item>
            <FooterTextLink href="/terms.pdf" target="_blank">
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "18px",
                }}
              >
                Terms of Use
              </Typography>
            </FooterTextLink>
          </Grid>
          <Grid item>
            <FooterTextLink href="mailto:hello@makemyvity.com">
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "18px",
                }}
              >
                Contact Us
              </Typography>
            </FooterTextLink>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Box
          sx={{
            color: "#fff",
            fontSize: "16px",
            mb: "40px",
          }}
        >
          Copyright © 2023 MyVity Corp. All rights reserved.
        </Box>
      </Grid>
    </Grid>
  );
};

export default Footer;
