import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Button, Box, Grid, Typography, useMediaQuery, useTheme, Chip } from '@mui/material';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import HomeCollage from '../../assets/home-collage.webp';
import DemoModal from '../../components/DemoModal';

export default function HomeSlider() {
  const sliderRef = React.useRef();
  const height = {
    xs: '600px',
  };
  var settings = {
    ref: sliderRef,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const slides = [<MemoryBookSlide height={height} />];

  // const gotoNext = () => {
  //   sliderRef.current.slickNext();
  // };

  // const gotoPrev = () => {
  //   sliderRef.current.slickPrev();
  // };

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        padding: '0px',
        minHeight: '450px',
      }}
    >
      <Slider {...settings}>
        {slides.map((slide, index) => {
          return <div key={index}>{slide}</div>;
        })}
      </Slider>
    </Box>
  );
}

const MemoryBookSlide = () => {
  const theme = useTheme();
  const showImage = useMediaQuery(theme.breakpoints.up('sm'));
  const [demoModal, setDemoModal] = useState(false);

  return (
    <>
      <Box>
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          wrap='wrap-reverse'
          sx={{ mt: '60px', pb: '40px' }}
        >
          <Grid item container xs={12} md={6}>
            <Grid item xs={1} md={2} />
            <Grid item xs={10} md={10}>
              <Box
                display='flex'
                flexDirection={'column'}
                sx={{
                  justifyContent: { xs: 'center', md: 'flex-start' },
                  textAlign: { xs: 'center', md: 'left' },
                  // background: 'red',
                  // pt: { xs: "60px", md: "100px" },
                  // mb: { xs: "60px", sm: "90px", lg: "60px" },
                }}
              >
                <Typography
                  variant='h2'
                  fontWeight={700}
                  sx={{
                    color: 'black',
                    zIndex: '9999',
                    mt: '60px',
                  }}
                >
                  World's First
                </Typography>
                <Typography
                  variant='h2'
                  fontWeight={700}
                  sx={{
                    color: 'black',
                    zIndex: '9999',
                  }}
                >
                  Fully Automated
                </Typography>
                <Typography
                  variant='h2'
                  fontWeight={700}
                  sx={{
                    color: 'black',
                    zIndex: '9999',
                  }}
                >
                  Yearbook Creator
                </Typography>
                <MemoryChips />
                <Typography
                  variant='h5'
                  fontWeight={400}
                  sx={{ mt: '20px', zIndex: '9999', color: 'black' }}
                ></Typography>
                <Typography variant='h6' fontWeight={400} sx={{ zIndex: '9999', color: 'black' }}>
                  Effortlessly, turn your moments into beautifully crafted Personalized Yearbooks.
                </Typography>

                <Box
                  display={'flex'}
                  flexWrap='wrap'
                  gap='20px'
                  sx={{
                    mt: '50px',
                    justifyContent: { xs: 'center', md: 'flex-start' },
                  }}
                >
                  <Button
                    variant='contained'
                    disableElevation
                    fullWidth={!showImage}
                    href={`${process.env.REACT_APP_PARENT_URL}/signup/parent`}
                    startIcon={<ShoppingCartOutlinedIcon sx={{ color: 'white' }} />}
                    sx={{
                      color: 'white',
                      background: 'black',
                      borderRadius: '6px',
                      fontWeight: '500',
                      fontSize: '18px',
                      border: 'solid 1px black',
                      // px: "20px",
                      // py: "24px",
                      minWidth: '225px',
                      '&:hover': {
                        background: 'black',
                      },
                    }}
                  >
                    Buy Memory Book
                  </Button>

                  <Button
                    variant='outlined'
                    fullWidth={!showImage}
                    onClick={() => {
                      window.location.href = "https://products.makemyvity.com/school/demo-request"
                    }}
                    sx={{
                      background: { xs: 'transparent', md: 'white' },
                      color: 'black',
                      borderRadius: '6px',
                      fontWeight: '500',
                      fontSize: '18px',
                      textTransform: 'none',
                      border: 'solid 1px rgba(45,40,40,1)',
                      minWidth: '225px',
                      '&:hover': {
                        border: 'solid 1px rgba(45,40,40,1)',
                      },
                    }}
                  >
                    Learn More
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={1} md={1} />
          </Grid>
          <Grid item container sm={12} md={6}>
            <Grid item xs={0} md={1}></Grid>
            <Grid item xs={12} md={9}>
              <img src={HomeCollage} alt='' style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={0} md={2}></Grid>
          </Grid>
        </Grid>
      </Box>
      <DemoModal open={demoModal} handleClose={() => setDemoModal(false)} />
    </>
  );
};

const MemoryChips = () => {
  return (
    <Box
      display={'flex'}
      direction='row'
      gap={1}
      flexWrap='wrap'
      sx={{
        mt: '20px',
        mb: '20px',
        justifyContent: { xs: 'center', md: 'flex-start' },
      }}
    >
      <Chip
        label='Highly Personalized'
        size='small'
        sx={{
          color: 'white',
          fontWeight: 700,
          bgcolor: 'darkorange',
          px: '4px',
          zIndex: '9999',
          textTransform: 'uppercase',
        }}
      />
      <Typography
        sx={{
          color: 'black',
          zIndex: '9999',
          fontSize: '27px',
          fontWeight: '700',
          p: 0,
          m: 0,
          lineHeight: '1.0',
        }}
      >
        +
      </Typography>
      <Chip
        size='small'
        label='Fully Automated'
        sx={{
          color: 'white',
          fontWeight: 700,
          bgcolor: 'brown',
          px: '4px',
          zIndex: '9999',
          textTransform: 'uppercase',
        }}
      />
    </Box>
  );
};
