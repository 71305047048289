import React from "react";
import {
  Typography,
  ImageList,
  useMediaQuery,
  Stack,
  Box,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SaveTeacherTime from "../../assets/save-teacher-time.webp";
import DelightParents from "../../assets/delight-parents.webp";
import EnhanceBrand from "../../assets/enhance-brand.webp";

const data = [
  {
    url: SaveTeacherTime,
    title: "Save Teacher's  Time",
    time: "2-3 mins",
    title1: "Create",
    title2: "Account",
    desc: "Let teachers take care of kids and do what they do best. We will take care of preserving memories.",
    quote:
      "Teachers don't have to worry about deleting,  sorting and organizing photos. Our AI gets busy so your teachers dont have to be.",

    // desc: "This will give you access to our platform and allow you to upload growing up memories & student portfolios, add a personalied note and customize the memory books as per your school's branding.",
    sticker: { text: "2-3 mins", bg: "rgba(0,0,0,0.09)" },
    button: "Signup your school",
  },
  {
    url: DelightParents,
    title: "Delight Families",
    time: "Just do what you do today",
    title1: "Take",
    title2: "Photos",
    desc: "Give parents an option to have a beautiful keepsake that preserves their child's growing up memories.",
    quote:
      "There is nothing more precious to a parent than the memories of their child's childhood. Give them an opportunity to preserve those memories.",

    // desc: "Take photos and upload to our platform using one or more of our custom solutions. We can even bullk download your school photos from other child care software providers with just a button click.",
    sticker: {
      text: "Just do what you do today",
      bg: "rgba(0,0,0,0.09)",
    },
    button: "Contact us to get started",
  },
  {
    url: EnhanceBrand,
    title: "Enhance Brand",
    time: "0 mins",
    title1: "Upload",
    title2: "Roster",
    desc: "Showcase the personalized memory books to the prospective parents and stand out from the crowd.",
    quote:
      "Showcase the school's students, staff, and activities. Use our memory books as a promotional tool to attract prospective students and their families.",
    sticker: { text: "3-5 mins", bg: "rgba(0,0,0,0.09)" },
    button: "Request sample",
  },
];

export default function HowItWorks() {
  const theme = useTheme();

  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const isSm = useMediaQuery(theme.breakpoints.down("md"));
  const isMd = useMediaQuery(theme.breakpoints.down("lg"));

  const numberOfCols = () => {
    if (isXs) {
      return 1;
    }
    if (isSm) {
      return 1;
    }
    if (isMd) {
      return 3;
    }
    return 3;
  };
  console.log("number of cols: ", numberOfCols(), isXs, isSm);
  return (
    <Box
      display={"flex"}
      alignItems="center"
      justifyContent={"center"}
      sx={{
        width: "100%",
        py: "60px",
        backgroundImage:
          "linear-gradient(260.92deg,#f5f9ff 12.21%,rgba(237,241,247,.2) 78.08%)",
      }}
    >
      <Box sx={{ width: { sm: "90%", xs: "90%", lg: "81%" } }}>
        <Typography variant={"h4"} component="span" fontWeight="400">
          👋 Goodbye to the old way of manually making keepsakes.{" "}
        </Typography>
        <Typography variant={"h4"} component="span" fontWeight="900">
          ❤️ to AI based automated personalized memory books.
        </Typography>

        <ImageList
          sx={{
            width: "100%",
            mt: "80px",
          }}
          cols={numberOfCols()}
          gap={(4 - numberOfCols()) * 20}
        >
          <HIWCard data={data[0]} />
          <HIWCard data={data[1]} />
          <HIWCard data={data[2]} />
        </ImageList>
      </Box>
    </Box>
  );
}

const HIWCard = ({ data }) => {
  return (
    <Box
      sx={{
        borderRadius: "8px",
        border: "solid 1px rgba(0,0,0,0.09)",
        bgcolor: "white",
        position: "relative",
        pb: "80px",
      }}
    >
      <img
        src={data.url}
        style={{
          width: "100%",
          aspectRatio: "1.5",
          objectFit: "cover",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      />
      <Stack sx={{ mx: "20px" }}>
        <Typography variant="h4" fontWeight="900" sx={{ mt: "40px" }}>
          {data.title}
        </Typography>
        <Typography variant="body1" sx={{ mt: "40px" }}>
          {data.desc}
        </Typography>
        <Box
          sx={{
            width: "60px",
            height: "4px",
            bgcolor: "red",
            mt: "40px",
            borderRadius: "8px",
          }}
        ></Box>

        <Box
          display={"flex"}
          justifyContent="start"
          alignItems="start"
          flexDirection={"row"}
          gap="10px"
          sx={{ mt: "20px", pb: "40px" }}
        >
          <i style={{ color: "#757575" }}>
            <Typography variant="body2" sx={{ fontSize: "14px" }}>
              {data.quote}
            </Typography>
          </i>
        </Box>
      </Stack>
      <Button
        variant="contained"
        href={`${process.env.REACT_APP_SCHOOL_URL}/signup/school`}
        sx={{
          ml: "20px",
          mt: "50px",
          mb: "30px",
          bgcolor: "black",
          position: "absolute",
          bottom: "0px",
          ":hover": {
            bgcolor: "black",
            opacity: 1,
            border: "solid 1px black",
          },
        }}
      >
        SIGNUP
      </Button>
    </Box>
  );
};

// const HIWCard = ({ data }) => {
//   return (
//     <Box display={"flex"} alignItems="center" justifyContent={"center"}>
//       <Card
//         elevation={0}
//         sx={{
//           maxWidth: "330px",
//           width: "90%",
//           height: "380px",
//           bgcolor: "rgba(0,0,0,0.01)",
//           border: "solid 1px rgba(0,0,0,0.09)",
//         }}
//       >
//         <CardContent>
//           <img
//             style={{
//               width: "60px",
//               height: "60px",
//               background: "brown",
//               marginTop: "10px",
//               borderRadius: "30px",
//             }}
//           ></img>
//           <Typography variant="h4" sx={{ mt: "30px" }}>
//             {data.title}
//           </Typography>
//           <Typography
//             variant="caption"
//             sx={{
//               color: "black",
//               px: "4px",
//               color: "black",
//               fontWeight: "500",
//             }}
//           >
//             {data.time}
//           </Typography>
//           <Typography variant="body1" sx={{ mt: "40px" }}>
//             {data.desc}
//           </Typography>

//           <Button
//             variant="text"
//             sx={{ p: 0, border: "none", mt: "30px" }}
//             endIcon={<ChevronRightIcon />}
//           >
//             {data.button}
//           </Button>
//         </CardContent>
//       </Card>
//     </Box>
//   );
// };

// const HIWCard = ({ data }) => {
//   return (
//     <Box
//       sx={{
//         width: "100%",
//         border: "solid 1px rgba(0,0,0,0.09)",
//         m: "0px",
//         p: "0px",
//       }}
//     >
//       <img
//         src={data.url}
//         style={{
//           width: "100%",
//           padding: "0px",
//           objectFit: "scale",
//           transform: "scale(1.01)",
//         }}
//       />
//       <CardContent>
//         <Typography
//           fontWeight={700}
//           component="span"
//           sx={{ typography: { xs: "h4", sm: "h5", md: "h4" } }}
//         >
//           {data.title1}{" "}
//         </Typography>

//         <Typography
//           gutterBottom
//           sx={{ typography: { xs: "h4", sm: "h5", md: "h4" } }}
//           fontWeight={700}
//           component="span"
//         >
//           {data.title2}
//         </Typography>
//         <Box sx={{ mt: "8px" }}></Box>
//         <Typography
//           sx={{
//             background: data.sticker.bg,
//             py: "4px",
//             px: "12px",
//             fontSize: "12px",
//             fontWeight: 500,
//             borderRadius: "2px",
//             display: "inline",
//           }}
//         >
//           {data.sticker.text}
//         </Typography>

//         <Typography variant="body1" color="text.secondary" sx={{ mt: "20px" }}>
//           {data.desc}
//         </Typography>
//       </CardContent>
//     </Box>
//   );
// }
