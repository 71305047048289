import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Typography, Grid } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Sheila from "../assets/customerReviews/sheila.webp";
import Cagatay from "../assets/customerReviews/cagatay.webp";
import Priya from "../assets/customerReviews/priya.webp";
import Fida from "../assets/customerReviews/fida.webp";
import Debanjali from "../assets/customerReviews/debanjali.webp";
import SaraVizcarrondo from "../assets/customerReviews/SaraVizcarrondo.jpg";
import ParisaMartin from "../assets/customerReviews/ParisaMartin.jpg";
import Safia from "../assets/customerReviews/safia.png";
import RenukaSharma from "../assets/customerReviews/RenukaSharma.jpeg";
import Roopa from "../assets/customerReviews/Roopa.jpeg";
import { callPostAPI } from "../helpers/api";
import { getApp } from "firebase/app";
import { getDownloadURL, getStorage, ref } from "firebase/storage";

const reviews = [
  {
    imageUrl: Safia,
    name: "Ms. Safia",
    role: "Owner",
    schoolName: "Golden Harvest Montessori",
    review: "I wanted to take a moment to express my heartfelt appreciation for the exceptional personalized memory books MyVity provided for the children at our preschool. As a preschool owner, I was truly impressed by the level of personalization and the profound impact these books had on both the children and their parents. The reaction from parents was incredible. I witnessed firsthand how a few parents were moved to tears as they flipped through the pages of their child's personalized memory book. These books not only captured precious moments but also became a tangible symbol of their child's growth and milestones during their time at our preschool. I recommend MVity's Personalized Memory Books to all schools and I am truly grateful for the joy and nostalgia they have brought to our preschool community.",
  },
  {
    imageUrl: RenukaSharma,
    name: "Ms. Renuka Sharma",
    role: "Owner",
    schoolName: "Genius Kids Milpitas",
    review: "We are thrilled to share our wonderful experience with MyVity, as they masterfully brought to life stunningly beautiful and uniquely personalized keepsake books for our students. The entire process was not only remarkably straightforward but also remarkably hands-off for our staff, thanks to MyVity's team handling of every detail. Their seamless and intuitive process created this one-of-a-kind keepsake without burdening our staff with additional tasks. MyVity's team took charge of every aspect, leaving us astounded by their efficiency and dedication. What truly sets MyVity apart is the level of personalization they achieved for each child. Every page of these books radiates the uniqueness of each student, capturing their journey, achievements, and growth during their preschool years. We would highly recommend MyVity to other schools.",
  },
  {
    imageUrl: Roopa,
    name: "Ms. Rupa",
    role: "Owner",
    schoolName: "Shooting Star",
    review: "I am happy to share my heartfelt appreciation for the incredible impact that MyVity's personalized memory books have brought to our school community. One of the features of MyVity's service is its ability to effortlessly bulk download photos from our Brightwheel account. Our teachers did not have to do anything and MyVity took care of every detail from selecting photos, creating layout, and adding beautiful rhyming text. What truly sets MyVity apart is the personalized touch they bring to each memory book. Their meticulously curated memory books captured every precious moment, from classroom activities to special events, ensuring that no memory goes undocumented.",
  },
  {
    imageUrl: SaraVizcarrondo,
    name: "Sara Vizcarrondo",
    role: "Teacher",
    schoolName: "Twinkle Child Care",
    review: "Twinkle Childcare used MyVity for their 2022-2023 yearbooks. In years past, the task of designing and ordering the yearbooks has been exclusively mine and it's been a highly time consuming and often stressful job, sometimes even necessitating overtime. This year, we used MyVity and the yearbooks took almost no time from my general work duties and certainly no overtime. Additionally, the cost is comparable to the companies I used prior, but with significantly less effort expended. We are very happy with the quality of the books and the timing/care of delivery. The ease was unmatchable and Shipra, the owner who worked with us directly, was super helpful, efficient and easy to work with. I will absolutely recommend MyVity to other schools for their yearbook needs and certainly use them again next year!",
  },
  {
    imageUrl: ParisaMartin,
    name: "Parisa Martin",
    role: "Director",
    schoolName: "Custer Parkway Preschool",
    review: "As a school director, I'm always on the lookout for innovative and hassle-free ways to create memorable experiences for our students. MyVity truly exceeded my expectations. The process was incredibly simple and stress- free. All we had to do was share the pictures in bulk and MyVity took care of the rest. The team at MyVity ensured that each child's book was beautifully personalized, capturing their unique personalities and interests. I highly recommend MyVity personalized books to all schools.It's a great way to celebrate each child's individuality and create lasting memories.",
  },
  {
    imageUrl: Sheila,
    name: "Sheila Brahme",
    role: "Owner",
    schoolName: "Lakeview Montessori",
    review: "Parents and children love the personalized memory books from MyVity. It is a novel gift item with preschool memories beautifuly put together in a keepsake that kids will cherish forerver.",
  },
  {
    imageUrl: Fida,
    name: "Fida Aslam",
    role: "Head Teacher",
    schoolName: "Lakeview Vista",
    review: "Love getting personalized memory books from MyVity. As a preschool teacher, I have no time to sort and filter pictures, but with MyVity, I don’t need to worry about all these details.",
  },

  {
    imageUrl: Priya,
    name: "Priya Agarwal",
    role: "Parent",
    schoolName: "",
    review: "Such a great book. I got emotional while reading it to my son. This is a great way to preserve his growing up memories. I would love to get it every year for my child.",
  },
  {
    imageUrl: Cagatay,
    name: "Cagatay Aycan",
    role: "Parent",
    schoolName: "",
    review: "A perfect personalized gift for your child. My child loved seeing his name, pictures, and avatar in the book. I love this concept of preschool memory book as these are most cherished growing-up memories.",
  },
  {
    imageUrl: Debanjali,
    name: "Debanjali",
    role: "Head Teacher",
    schoolName: "",
    review: "MyVity created personalized memory books for our graduating students. The memory books captured special moments and memories made during the preschool years. MVity added a special touch to each book and made them a unique keep sake.",
  },
];

var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
      },
    },
  ],
};

export default function CustomerReviews() {
  const sliderRef = useRef();

  const [testimonials, setTestimonials] = useState(reviews);

  const getTestimonials = async () => {
    let response = await callPostAPI({}, "/testimonial/get");

    if (response?.success) {
      let testimonialPromise = response.testimonials.map((testimonial) => {
        if (testimonial.imagePath) {
          const firebaseApp = getApp();
          const storage = getStorage(
            firebaseApp,
            `gs://${process.env.REACT_APP_FIREBASE_PROJECT_ID}-loohcs`
          );

          return getDownloadURL(ref(storage, testimonial.imagePath)).catch((error) => {
            console.log(`Error retrieving photo URL: ${error}`);
            return null;
          });
        } else {
          return null;
        }
      });

      let testimonials = [];
      let resolvedImages = await Promise.all(testimonialPromise);
      resolvedImages.forEach((url, index) => {
        testimonials.push({ ...response.testimonials[index], imageUrl: url });
      });

      setTestimonials(testimonials);
    } else {
      setTestimonials(reviews);
    }
  }

  // useEffect(() => {
  //   getTestimonials();
  // }, []);

  return (
    <Box
      display={"flex"}
      flexDirection="column"
      alignItems="center"
      justifyContent={"center"}
      sx={{
        width: "100%",
        py: "80px",
        backgroundImage:
          "linear-gradient(260.92deg,#f5f9ff 12.21%,rgba(237,241,247,.2) 78.08%)",
      }}
    >
      <Box sx={{ width: { sm: "90%", xs: "90%", lg: "81%" } }}>
        <Typography variant={"h4"} component="span" fontWeight="400">
          ❤️ Our customers love us.{" "}
        </Typography>
        <Typography variant={"h4"} component="span" fontWeight="900">
          Personalized Memory Book that preserves childhood memories and is
          effortless to create. What else can you ask for?
        </Typography>
        <Slider {...settings} ref={sliderRef}>
          {testimonials.map((item, index) => {
            return (
              <Box sx={{ px: "8px" }} key={index}>
                <CustomerReviewCard data={item} />
              </Box>
            );
          })}
        </Slider>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "30px",
            mt: "20px",
            justifyContent: "center",
          }}
        >
          <Button
            aria-label="delete"
            variant="contained"
            sx={{
              width: "44px",
              height: "44px",
              borderRadius: "22px",
              backgroundColor: "black",
              color: "white",
              p: "0px",
              m: 0,
              "&:hover": {
                transition: "0.2s",
                backgroundColor: "black",
              },
            }}
            onClick={(e) => sliderRef.current.slickPrev()}
          >
            <ArrowBackIcon />
          </Button>
          <Button
            aria-label="delete"
            variant="contained"
            sx={{
              width: "44px",
              height: "44px",
              borderRadius: "22px",
              backgroundColor: "black",
              color: "white",
              p: "0px",
              m: 0,
              "&:hover": {
                transition: "0.2s",
                backgroundColor: "black",
              },
            }}
            onClick={(e) => sliderRef.current.slickNext()}
          >
            <ArrowForwardIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

const CustomerReviewCard = ({ data }) => {
  return (
    <Grid
      container
      direction="row"
      alignItems={"top"}
      justifyContent="center"
      sx={{
        borderRadius: "6px",
        border: "solid 1px rgba(0,0,0,0.04)",
        bgcolor: "white",
        p: "20px",
        pb: "30px",
        mt: "60px",
      }}
    >
      <Grid item xs={12} sm={10} sx={{ pr: "6px" }}>
        <img
          src={data.imageUrl}
          style={{ width: "90%", objectFit: "cover", borderRadius: "8px" }}
          alt="Loading..."
        ></img>
      </Grid>
      <Grid item xs={12} sm={12} sx={{ pl: "6px", pr: "20px" }}>
        <Box
          sx={{
            px: "8px",
            py: "20px",
            mt: "10px",
            position: "relative",
            height: "350px",
          }}
        >
          <img
            src={process.env.PUBLIC_URL + "quote.png"}
            style={{ width: "40px" }}
            alt="Quote"
          ></img>

          <Box
            sx={{
              m: "10px 0",
              maxHeight: "210px",
              overflow: "scroll",
            }}
          >
            <Typography
              sx={{ mt: "20px", color: "primary.lightText" }}
              align="center"
            >
              {data.review}
            </Typography>
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom: "0px",
              left: "50%",
              transform: "translate(-50%)",
              width: "100%",
            }}
          >
            <Typography
              variant="body1"
              component="div"
              align="center"
              fontWeight={900}
            >
              {data.name.toUpperCase()}
            </Typography>

            <Typography
              variant="caption"
              component="div"
              align="center"
              sx={{
                color: "primary.lightText",
                textTransform: 'uppercase'
              }}
            >
              {data?.role}{data.schoolName && `, ${data.schoolName}`}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
