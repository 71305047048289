import * as React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Typography,
  Grid,
  useMediaQuery,
  Link,
  Box,
  Stack,
  ImageList,
  Button,
} from "@mui/material";
import Carousel from "../../components/Carousel";
import CustomerReviews from "../../components/CustomerReviews";
import FormModal from "../../components/DemoModal";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ResponsiveAppBar from "../../components/appBar";
import HorizontalScroll from "../../components/HorizontalScroll";
import ChipCloud from "../../components/ChipCloud";
import HomeSlider from "./slider";
import MemoryBookHighlights from "./slider/memoryBookHighlights";
import NoContractsCard from "./NoContractsCard";
import HowItWorks from "./HowItWorks";
import { useTheme } from "@mui/material/styles";
import WaysToUploadPhoto from "./waysToUploadPhoto";
import WhatIsPersonalized from "./whatIsPersonalized";

export default function Home() {
  const matches = useMediaQuery("(max-width:500px)");
  console.log("in home");
  const handleSignupSchool = () => {};

  const handleBuy = () => {};

  const theme = useTheme();

  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("lg"));

  const numberOfCols = () => {
    if (isXs) {
      return 2;
    }
    if (isMd) {
      return 2;
    }
    return 4;
  };

  return (
    <>
      <ResponsiveAppBar />
      <HomeSlider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box sx={{ mt: "10px" }}></Box>
        <Box sx={{ width: "100%", height: "10px", bgcolor: "white" }}></Box>
        <WhatIsPersonalized />
        <MemoryBookHighlights />
        <Box sx={{ width: "100%", height: "10px", bgcolor: "white" }}></Box>
        <HowItWorks />
        <Box sx={{ width: "100%", height: "10px", bgcolor: "white" }}></Box>

        <NoContractsCard />
        <Box sx={{ width: "100%", height: "10px", bgcolor: "white" }}></Box>
        <WaysToUploadPhoto />
        <Box sx={{ width: "100%", height: "10px", bgcolor: "white" }}></Box>
        <CustomerReviews />
        {/* <HomeThickDivider /> */}
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <HomeTitleHeader
          title={"What Our Customers Say"}
          desc1={
            "Schools love us because of our proprietary AI based automated personalization technology."
          }
          desc2={
            "Parents love us because of the quality, service and  joy our products bring to the families."
          }
        /> */}

      {/* <Box sx={{ mt: "80px", mb: "160px" }}>
          <CustomerReviews />
        </Box>
      </Box> */}

      <Footer />
    </>
  );
}

const HomeTitleHeader = ({ title, desc1, desc2 }) => {
  return (
    <>
      <Typography variant="h3" align="center" fontWeight={700}>
        {title}
      </Typography>
      <Typography
        variant="body1"
        align="center"
        fontSize="18px"
        sx={{
          lineHeight: "1.35",
          mt: "12px",
          color: "#757575",
        }}
      >
        {desc1}
      </Typography>
      <Typography
        variant="body1"
        align="center"
        fontSize="18px"
        sx={{ color: "#757575" }}
      >
        {desc2}
      </Typography>
    </>
  );
};

const TextArrowStack = ({ text, icon, handleClick }) => {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ cursor: "pointer" }}
        onClick={handleClick}
      >
        <Link
          variant="body1"
          fontWeight="400"
          fontSize="18px"
          // onClick={() => {
          //   window.open("https://makemyvity.com/");
          // }}
          underline="none"
          sx={{ color: "rgba(56,116,203,1)" }}
        >
          {text}
        </Link>
        <KeyboardArrowRightIcon sx={{ color: "rgba(56,116,203,1)" }} />
      </Stack>
    </>
  );
};

const HomeThickDivider = () => {
  return (
    <Box
      sx={{
        height: "10px",
        backgroundColor: "rgba(0,0,0,0.09)",
        // my: "80px",
        width: "100%",
      }}
    ></Box>
  );
};
