import React from 'react';
import { ArrowBack, ArrowForward, Close } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import MediaPreview from './MediaPreview';

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      sx={{
        position: 'absolute',
        color: 'primary.main',
        top: '50%',
        right: '-15px',
        zIndex: 2,
        bgcolor: 'white',
        ':hover': {
          bgcolor: 'white',
        },
        filter: 'drop-shadow(1px 1px 5px #00000055)',
      }}
    >
      <ArrowForward />
    </IconButton>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      sx={{
        position: 'absolute',
        color: 'primary.main',
        top: '50%',
        left: '-15px',
        zIndex: 2,
        bgcolor: 'white',
        ':hover': {
          bgcolor: 'white',
        },
        filter: 'drop-shadow(1px 1px 5px #00000055)',
      }}
    >
      <ArrowBack />
    </IconButton>
  );
}

const PhotoModal = ({ open, handleClose, photosList, currPhotoId }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const slickSettings = {
    centerMode: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: isSmallScreen ? '5px' : '100px',
    initialSlide: photosList.findIndex((item) => item.pid === currPhotoId),
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
  };

  return (
    <Dialog maxWidth={'md'} fullWidth open={open} onClose={handleClose}>
      <DialogTitle sx={{ px: '30px' }}>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 10,
            top: 10,
            bgcolor: 'white',
            ':hover': {
              bgcolor: 'white',
            },
            color: 'gray',
          }}
        >
          <Close
            sx={{
              fontSize: '18px',
            }}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <Box
          sx={{
            px: '30px',
            width: '100%',
            pb: '20px',
          }}
        >
          <Slider {...slickSettings}>
            {photosList.map((item) => (
              <Box
                key={item.pid}
                sx={{
                  height: '70vh',
                  px: '5px',
                  boxSizing: 'border-box',
                }}
              >
                <MediaPreview url={item.url} type={item.med} objectFit={'contain'} />
              </Box>
            ))}
          </Slider>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PhotoModal;
