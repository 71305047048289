import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900.css";

import RouteProvider from "./routes";
import Theme from "./theme";

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <RouteProvider>
        <CssBaseline />
      </RouteProvider>
    </ThemeProvider>
  );
}

export default App;
