import React from 'react';
import { Backdrop } from '@mui/material';
import spinnerGif from '../assets/spinner.gif';

export default function Spinner({ open, handleClose, isBackdrop = true, sx }) {
    return (
        <Backdrop
            sx={{
                color: isBackdrop ? '#fff' : 'transparent',
                zIndex: 999999,
                background: 'rgba(0, 0, 0, 0.2)',
                ...sx,
            }}
            open={open}
            onClick={handleClose}
            invisible={!isBackdrop}
        >
            <img src={spinnerGif} alt='Loading...' style={{ width: '36px', height: '36px' }} />
        </Backdrop>
    );
}