import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import { callPostAPI } from "../helpers/api";

export default function RequestDialog({
  open,
  handleClose,
  title,
  text,
  type,
}) {
  const [email, setEmail] = useState("");

  const handleSampleRequest = async (dialogType) => {
    await callPostAPI({
      email: email,
      type: dialogType,
    }, "/demo/request");
    handleClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            sx={{
              mt: "40px",
              "& .MuiInputBase-input": {
                fontSize: "18px",
                lineHeight: "24px",
                paddingBottom: "8px",
              },
              "& .MuiInput-underline:before": {
                borderBottomColor: "primary.black",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "primary.black",
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: 'primary.black'
              }
            }}
            onChange={(e) => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{ p: '20px 28px' }}>
          <Button
            variant="text"
            sx={{
              color: 'primary.black',
              textTransform: "none",
              backgroundColor: '#E4E4E4',
              px: '14px',
              '&:hover': {
                backgroundColor: '#efefef'
              }
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              border: "none",
              ":hover": {
                backgroundColor: "rgba(54,45,45,1)",
              },
            }}
            onClick={() => handleSampleRequest(type)}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
