import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { Avatar, Button, Toolbar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Menu, MenuItem, Link } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import AdbIcon from '@mui/icons-material/Adb';
import DemoModal from './DemoModal';
import ShoppingCart from './ShoppingCart';
import theme from '../theme';
import { useNavigate } from 'react-router-dom';
import MyVityLogo from './MyVityLogo';
import School from '../assets/school.webp';
import Parent from '../assets/parent.webp';

const pages = [];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar({ showLogin = true }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [showDemo, setShowDemo] = React.useState(false);

  const [anchorElLogin, setAnchorElLogin] = React.useState(null);
  const handleOpenLoginMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseLoginMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const Navigate = useNavigate();
  console.log('anchor', anchorElLogin);

  const handleLoginSelection = (type) => {
    setAnchorElLogin(null);
  };

  return (
    <>
      <DemoModal open={showDemo} handleClose={(e) => setShowDemo(false)} />
      {/* <Box sx={{
        backgroundColor: '#bb271a',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        p: '15px'
      }}>
        <Typography variant="h6" sx={{ color: '#fff' }}>Special Holiday Offer! Get 10% off on Personalized Memory Books, when you buy before Dec 31st, 2023</Typography>
      </Box> */}
      <AppBar
        position='static'
        elevation={0}
        sx={{
          backgroundColor: 'transparent',
          color: 'black',
          display: 'flex',
          justifyContent: 'center',
          margin: '0px',
          position: 'relative',
        }}
      >
        <Container maxWidth='xl'>
          <Toolbar disableGutters>
            <Box
              sx={{
                display: { md: 'flex' },
                flexGrow: 1,
                mr: 1,
                cursor: 'pointer',
              }}
              onClick={(e) => Navigate('/')}
            >
              <Box sx={{ height: { xs: '24px', sm: '36px' } }}>
                <MyVityLogo color='black' />
              </Box>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {pages.map((page) => (
                <Link
                  key={page}
                  sx={{
                    color: 'primary.black',
                    display: 'block',
                    mx: '20px',
                    textDecoration: 'none',
                    cursor: 'pointer',
                    transition: '0.3s all',
                  }}
                >
                  {page}
                </Link>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              {/* <ShoppingCart /> */}
              {showLogin && (
                <Link
                  sx={{ textDecoration: 'none' }}
                  href={`${process.env.REACT_APP_SCHOOL_URL}/signin`}
                >
                  <Button
                    ref={anchorElLogin}
                    variant='text'
                    sx={{
                      mr: { xs: '12px', sm: '24px' },
                      color: 'primary.black',
                      textTransform: 'none',
                      backgroundColor: '#E4E4E4',
                      '&:hover': {
                        backgroundColor: '#efefef',
                      },
                    }}
                    // onClick={(e) => {
                    //   setAnchorElLogin(e.currentTarget);
                    // }}
                  >
                    Log in
                  </Button>
                </Link>
              )}
              {/* <Button
                variant='contained'
                disableElevation
                onClick={(e) => setShowDemo(true)}
                sx={{
                  color: 'primary.white',
                  textTransform: 'none',
                  ':hover': {
                    backgroundColor: 'rgba(54,45,45,1)',
                  },
                }}
              >
                Request demo
              </Button> */}
              <Menu
                id='menu-appbar'
                anchorEl={anchorElLogin}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElLogin)}
                onClose={(e) => setAnchorElLogin(null)}
              >
                <Link
                  href={`${process.env.REACT_APP_PARENT_URL}/signin/parent`}
                  sx={{
                    textDecoration: 'none',
                    color: theme.palette.primary.black,
                  }}
                >
                  <MenuItem key={'parent'} onClick={(e) => setAnchorElLogin(null)}>
                    <Avatar src={Parent}></Avatar>
                    <Typography sx={{ ml: '12px' }}>Parent</Typography>
                  </MenuItem>
                </Link>
                <Link
                  href={`${process.env.REACT_APP_SCHOOL_URL}/signin/school`}
                  sx={{
                    textDecoration: 'none',
                    color: theme.palette.primary.black,
                  }}
                >
                  <MenuItem key={'school'} onClick={(e) => setAnchorElLogin(null)}>
                    <Avatar src={School}></Avatar>
                    <Typography sx={{ ml: '12px' }}>School</Typography>
                  </MenuItem>
                </Link>
              </Menu>

              <Menu
                sx={{ mt: '45px' }}
                id='menu-appbar'
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign='center'>{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
export default ResponsiveAppBar;
