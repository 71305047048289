import React, { useEffect, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import UnsubscribeImg from '../assets/unsubscribe.jpg';
import { callPostAPI } from '../helpers/api';
import Spinner from '../components/Spinner';

const UnsubscribeEmail = () => {
    const { search } = useLocation();
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(search);
    const email = queryParams.get('email');

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isUnsubscribed, setIsUnsubscribed] = useState(false);

    const unsubsribeEmail = async () => {
        try {
            setIsError(false);
            setIsUnsubscribed(false);
            setIsLoading(true);

            let response = await callPostAPI({ email }, '/email/unsubscribe');
            setIsLoading(false);
            if (response.success) {
                setIsUnsubscribed(true);
                setTimeout(() => {
                    navigate('/');
                }, 3000);
            } else {
                setIsError(true);
            }
        } catch (error) {
            console.log("Error:", error);
            setIsLoading(false);
            setIsError(true);
        }
    }

    useEffect(() => {
        if (!email) {
            navigate('/');
        }
    }, [email, navigate]);

    return (
        <>
            <Box>
                {isLoading && (<Spinner open={true} />)}
                <Box sx={{
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#f8f7fb'
                }}>
                    <Box sx={{
                        display: 'flex',
                        backgroundColor: "#fff",
                        width: { xs: '90%', md: '70%' },
                        minHeight: '70%',
                        borderRadius: '13px',
                        boxShadow: '0px 0px 20px 5px #0000001a'
                    }}>
                        <Box sx={{
                            width: { xs: '100%', sm: '50%' },
                            p: '20px'
                        }}>
                            <Stack sx={{
                                height: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                {!isUnsubscribed ? (
                                    <>
                                        <Typography variant='h4' sx={{ textAlign: 'center' }}>Unsubscribe?</Typography>
                                        <Typography sx={{
                                            mt: '40px',
                                            color: 'rgba(0,0,0,0.5)',
                                            textAlign: 'center'
                                        }}>We understand that inboxes can get crowded, and we respect your decision. By unsubscribing, you will no longer receive:</Typography>
                                        <Box
                                            component={'ul'}
                                            sx={{ color: 'rgba(0,0,0,0.5)' }}
                                        >
                                            <Box component={'li'}>Updates on our latest products and services</Box>
                                            <Box component={'li'}>Special offers and exclusive discounts</Box>
                                            <Box component={'li'}>Invitations to events and webinars</Box>
                                        </Box>
                                        <Typography sx={{
                                            mt: '10px',
                                            color: 'rgba(0,0,0,0.5)',
                                            textAlign: 'center'
                                        }}>Do you still want to unsubscribe?</Typography>
                                        <Box sx={{
                                            mt: '30px',
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: '20px'
                                        }}>
                                            <Button onClick={unsubsribeEmail} sx={{
                                                backgroundColor: '#D3464D',
                                                color: '#fff',
                                                border: 'none',
                                                px: '20px',
                                                textTransform: 'none',
                                                transition: '.3s all',
                                                '&:hover': {
                                                    backgroundColor: '#be3f45'
                                                }
                                            }}>Unsubscribe</Button>
                                            <Button onClick={() => navigate('/')} sx={{
                                                backgroundColor: 'rgba(0,0,0,0.1)',
                                                border: 'none',
                                                color: '#000',
                                                px: '20px',
                                                textTransform: 'none',
                                                transition: '.3s all'
                                            }}>Cancel</Button>
                                        </Box>
                                        {isError && (
                                            <Typography sx={{
                                                mt: '10px',
                                                color: '#D3464D',
                                                textAlign: 'center'
                                            }}>Could not unsubscribe, please try again!</Typography>
                                        )}
                                    </>
                                ) : (
                                    <Typography variant='h6' sx={{
                                        textAlign: 'center',
                                        color: '#3f745b'
                                    }}>You have been unsubscribed successfully!</Typography>
                                )}
                            </Stack>
                        </Box>
                        <Box sx={{
                            width: '50%',
                            display: { xs: 'none', sm: 'flex' },
                            justifyContent: 'center',
                            alignItems: 'center',
                            p: '10px'
                        }}>
                            <Box sx={{
                                width: '100%',
                                height: 'auto',
                                maxHeight: '100%',
                                '& img': {
                                    width: '100%',
                                    height: '100%',
                                    display: 'block'
                                }
                            }}>
                                <Box
                                    component={'img'}
                                    src={UnsubscribeImg}
                                    alt='Unsubscribe Email'
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default UnsubscribeEmail;