import React, { useState, useCallback } from "react";
import {
  Close,
  ImageOutlined,
  InfoOutlined,
  MailOutline,
} from "@mui/icons-material";
import { Stack } from "@mui/system";
import { Box, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import moment from "moment";

import QuoteImage from "../../../assets/flyers/quote.png";

import ThemeButton from "../components/ThemeButton";
import ThemeTextField from "../components/ThemeTextField";
import InfoItem from "./InfoItem";

const FlyerForm = ({
  open,
  handleFormClose,
  styleData,
  flyerData,
  fieldsData,
  changeFlyerData,
  changeFieldsData,
  handleFormModal,
}) => {
  const displayDateFormat = "MMM-DD-YYYY";
  const inputDateFormat = "YYYY-MM-DD";
  const displayTimeFormat = "hh:mma";
  const inputTimeFormat = "HH:mm";

  const [localfieldsData, setLocalFieldsData] = useState(fieldsData);

  const changeFieldValue = (id, value) => {
    let newFieldsData = [...fieldsData];

    // Update fieldsData
    const idx = fieldsData.findIndex((configObj) => configObj.id === id);
    if (idx < 0) {
      return;
    }
    newFieldsData[idx].value = value;

    setLocalFieldsData(newFieldsData);
  };

  const getTextField = (config, type) => {
    return (
      <Grid item xs={12} sm={9} key={config.placeholder}>
        <ThemeTextField
          multiline={type === "textarea"}
          styleData={styleData}
          icon={config.icon}
          key={config.placeholder}
          id={config.id}
          placeholder={config.placeholder}
          value={config.value}
          type={type}
          onChange={(e) => {
            changeFieldValue(config.id, e.target.value);
          }}
        />
      </Grid>
    );
  };

  const getDateTimeField = (config, type) => {
    // Get formated date/time string from input date/time for flyer
    const getFormattedDateTimeValue = (type, value) => {
      let formatedValue = "";
      if (type === "date")
        formatedValue = moment(value, inputDateFormat).format(
          displayDateFormat
        );
      if (type === "time")
        formatedValue = moment(value, inputTimeFormat).format(
          displayTimeFormat
        );

      return formatedValue;
    };

    // Get input format from formated date/time string
    const getDateTimeInputValue = (value, type) => {
      const momentObj = moment();
      if (type === "date") {
        if (value) {
          return moment(value, displayDateFormat).format(inputDateFormat);
        } else {
          // On initial load
          changeFieldValue(config.id, momentObj.format(displayDateFormat));
          return momentObj.format(inputDateFormat);
        }
      }
      if (type === "time") {
        if (value) {
          return moment(value, displayTimeFormat).format(inputTimeFormat);
        } else {
          // On initial load
          changeFieldValue(config.id, momentObj.format(displayTimeFormat));
          return momentObj.format(inputTimeFormat);
        }
      }
      return "";
    };

    return (
      <Grid item xs={12} sm={9} key={config.placeholder}>
        <ThemeTextField
          styleData={styleData}
          type={type}
          icon={config.icon}
          inputProps={{
            min: moment().format(inputDateFormat),
          }}
          key={config.placeholder}
          id={config.id}
          value={getDateTimeInputValue(config.value, type)}
          onChange={(e) => {
            changeFieldValue(
              config.id,
              getFormattedDateTimeValue(type, e.target.value)
            );
          }}
        />
      </Grid>
    );
  };

  const handleSave = useCallback(() => {
    let newFlyerData = { ...flyerData };

    // Find and set value in config array for each flyer
    Object.entries(flyerData).forEach(([key, configArray]) => {
      let newConfigArray = [...configArray];
      // Loop on all fields
      localfieldsData.forEach((fieldConfig) => {
        const idx = configArray.findIndex(
          (configObj) => configObj.id === fieldConfig.id
        );
        // No field id found
        if (idx < 0) {
          return;
        }
        newConfigArray[idx].value = fieldConfig.value;
        newFlyerData[key] = newConfigArray;
      });
    });

    changeFieldsData(localfieldsData);
    changeFlyerData(newFlyerData);
    handleFormModal();
  }, [localfieldsData]);

  return (
    <Dialog
      maxWidth={"lg"}
      fullWidth
      open={open}
      onClose={handleFormClose}
      sx={{
        "& .MuiBackdrop-root": {
          backdropFilter: "blur(2px)",
        },
      }}
      PaperProps={{
        sx: {
          overflowY: "visible",
          borderRadius: "30px",
          margin: "45px",
        },
      }}
    >
      {/* Close */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: "-40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
          width: "30px",
          height: "30px",
          borderRadius: "100px",
        }}
      >
        <Close
          onClick={handleFormModal}
          sx={{
            cursor: "pointer",
            color: styleData.color.main,
          }}
        />
      </Box>
      <DialogContent
        sx={{
          p: 0,
          borderRadius: "30px",
        }}
      >
        <Stack flexDirection={{ xs: "column", sm: "row" }}>
          {/* Sec - 1 : fields, title */}
          <Stack
            flexBasis={"50%"}
            sx={{
              p: { xs: "30px", sm: "40px" },
            }}
          >
            {/* Title */}
            <Typography
              variant="h4"
              fontWeight={700}
              sx={{
                color: styleData.color.main,
              }}
            >
              Personalize Your Flyer
            </Typography>
            {/* Form Fields */}
            <Grid container spacing={2} mt={1}>
              {localfieldsData.map((config) => {
                if (config.type === "text") {
                  return getTextField(config, "text");
                }
                if (config.type === "textarea") {
                  return getTextField(config, "textarea");
                }
                if (config.type === "date") {
                  return getDateTimeField(config, "date");
                }
                if (config.type === "time") {
                  return getDateTimeField(config, "time");
                } else {
                  return "";
                }
              })}
              {/* Save */}
              <Grid item xs={12}>
                <ThemeButton
                  variant="contained"
                  styleData={styleData}
                  onClick={handleSave}
                  text={"Save"}
                  sx={{
                    px: "55px",
                    py: "5px",
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} mt={2}>
              <Typography
                variant="body2"
                fontWeight={500}
                sx={{
                  opacity: 0.6,
                }}
              >
                By continuing, you agree to MyVity's Terms of Use. Read
                our&nbsp;
                <a href="/privacy-policy.pdf" target="_blank">
                  Privacy Policy.
                </a>
              </Typography>
            </Grid>
          </Stack>
          {/* Sec - 2: Process Info, testimonial */}
          <Stack
            flexBasis={"50%"}
            sx={{
              p: { xs: "30px", sm: "40px" },
              bgcolor: styleData.color.light,
            }}
          >
            {/* Process */}
            <Stack my={1}>
              <InfoItem
                icon={
                  <InfoOutlined
                    sx={{
                      color: styleData.color.main,
                    }}
                  />
                }
                titleText={"Personalise your flyer"}
                subTitleText={
                  "Tell us your school name and event details, and we will personalise your flyer"
                }
              />
              <InfoItem
                icon={
                  <ImageOutlined
                    sx={{
                      color: styleData.color.main,
                    }}
                  />
                }
                titleText={"Select your flyer"}
                subTitleText={
                  "Just click on the flyer you like and click on Get Your Flyer"
                }
              />
              <InfoItem
                icon={
                  <MailOutline
                    sx={{
                      color: styleData.color.main,
                    }}
                  />
                }
                titleText={"Get your flyer by email"}
                subTitleText={
                  "We will email you a PDF and an image of your personalised flyer for you to share with the families"
                }
              />
            </Stack>
            {/* Testimonial */}
            <Stack
              gap={1}
              width={{ xs: "100%", sm: "90%" }}
              mt={"20px"}
              sx={{
                alignSelf: "center",
              }}
            >
              <img
                src={QuoteImage}
                alt="quote"
                width={"30px"}
                style={{
                  alignSelf: "center",
                }}
              />
              <Typography
                variant="body1"
                textAlign={"center"}
                mt={"10px"}
                sx={{
                  color: "#333",
                }}
              >
                MyVity's personalised content and automated design services have
                transformed our preschool's communication with parents. The
                Creative and child-friendly content has truly connected with our
                little learners and their families. And best of all, it is free
                &#128512;
              </Typography>
              <Typography
                textAlign={"center"}
                variant="body1"
                sx={{
                  fontSize: "15px",
                  color: "#333",
                }}
              >
                - Samantha
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default FlyerForm;
