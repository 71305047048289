import React from 'react';
import { AppBar, Toolbar, Button, Container, Box } from '@mui/material';
import BookADemoButton from './component/BookADemoButton';
import { Link } from 'react-router-dom';

const MyAppBar = () => {
  return (
    <AppBar
      position='static'
      disableGutters
      sx={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        padding: 0,
        margin: 0,
        mt: 2,
        '& .MuiToolbar-root': { padding: 0 },
      }}
    >
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          padding: 0,
          margin: 0,
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 0,
            margin: 0,
            minHeight: 'auto', // This might help if Toolbar has a default minHeight
          }}
        >
          {/* Logo on the left */}
          <Link to='/'>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: 0,
                margin: 0,
              }}
            >
              <img src='/logoBlack.png' alt='MyLogo' style={{ height: '40px' }} />
            </Box>
          </Link>
          {/* Buttons on the right */}
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              padding: 0,
              margin: 0,
            }}
          >
            {/* <Button color='inherit' sx={{ padding: 0 }}>
              Login
            </Button> */}
            <BookADemoButton />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default MyAppBar;
