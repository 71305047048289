import React, { useEffect } from 'react';

const DemoRequest = () => {
    useEffect(() => {
        window.location.href = `https://products.makemyvity.com/school/demo-request`;
    }, []);

    return <></>;
}

export default DemoRequest;