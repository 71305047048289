import React from 'react';
import { Typography, ImageList, useMediaQuery, Stack, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FundRaising from '../../assets/fund-raising.webp';
import NoMinimum from '../../assets/no-minimum.webp';
import NoCost from '../../assets/no-cost.webp';

function formatString(s, sx) {
  return <></>;
}

const data = [
  {
    url: NoCost,
    title: 'No Cost',
    desc: 'Schools partner with us year after year because they love our personalized yearbooks and customer service. And by the way, you are never locked into a contract.',
    emoji: '🤩',
  },
  {
    url: FundRaising,
    title: 'Raise Funds',
    desc: " Raise funds by offering a product that every parent wants. Personalized Yearbook is perfect for fund raising as every parent wants to preserve their child's growing up memories.",
    emoji: ' 🙏',
  },
  {
    url: NoMinimum,
    title: 'No Minimum',
    desc: 'No minimum book orders. Whether you are a home-based center  or a large multi-site facility, the memories are equally precious. We got you covered',
    emoji: ' 👍',
  },
];

export default function NoContractsCard() {
  const theme = useTheme();

  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const isMd = useMediaQuery(theme.breakpoints.down('lg'));

  const numberOfCols = () => {
    if (isXs) {
      return 1;
    }
    if (isMd) {
      return 3;
    }
    return 3;
  };
  return (
    <Box
      display={'flex'}
      flexDirection='column'
      alignItems='center'
      justifyContent={'center'}
      sx={{
        width: '100%',
        py: '80px',
        backgroundImage: 'linear-gradient(260.92deg,#f5f9ff 12.21%,rgba(237,241,247,.2) 78.08%)',
      }}
    >
      <Box sx={{ width: { sm: '90%', xs: '90%', lg: '81%' } }}>
        <Typography variant={'h4'} component='span' fontWeight='400'>
          Our service is Simple, Flexible and Risk free.{' '}
        </Typography>
        <Typography variant={'h4'} component='span' fontWeight='900'>
          🤝 Partner with us and give the families an opportunity to preserve the school memories of
          their little ones.
        </Typography>
        <ImageList
          cols={numberOfCols()}
          gap={20}
          sx={{
            width: '100%',
            mt: '80px',
          }}
        >
          <NCCard data={data[0]} />
          <NCCard data={data[1]} />
          <NCCard data={data[2]} />
        </ImageList>
      </Box>
    </Box>
  );
}

// const NCCard = ({ data }) => {
//   return (
//     <Card sx={{ width: "300px", height: "450px" }}>
//       <CardContent></CardContent>
//     </Card>
//   );
// };

const NCCard = ({ data }) => {
  return (
    <Box
      sx={{
        width: '100%',
        border: 'solid 1px rgba(0,0,0,0.09)',
        borderRadius: '8px',
        bgcolor: 'white',
        position: 'relative',
        pb: '130px',
      }}
    >
      <img
        src={data.url}
        style={{
          width: '100%',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        }}
      />
      <Stack sx={{ mx: '20px', position: 'relative' }}>
        <Typography variant='h4' fontWeight='900' sx={{ mt: '40px' }}>
          {data.title}
        </Typography>
        <Typography variant='body1' sx={{ mt: '40px' }}>
          {data.desc}
        </Typography>
      </Stack>
      <Typography
        sx={{
          mx: '20px',
          mb: '20px',
          fontSize: '45px',
          position: 'absolute',
          bottom: '0px',
        }}
      >
        {data.emoji}
      </Typography>
    </Box>
  );
};
