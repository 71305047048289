import React from "react";
import { useTheme } from "@mui/material/styles";
import { ImageList, useMediaQuery } from "@mui/material";
import ProductCard from "./ProductCard";

export default function Books() {
  const theme = useTheme();

  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const isSm = useMediaQuery(theme.breakpoints.down("lg"));
  const isMd = useMediaQuery(theme.breakpoints.up("lg"));

  console.log(isXs, isSm, isMd, window.innerWidth);
  const numberOfCols = () => {
    if (isXs) {
      return 1;
    }
    if (isSm) {
      return 2;
    }
    return 3;
  };
  return (
    <ImageList sx={{ width: "80%" }} cols={numberOfCols()} gap={20}>
      {[1, 2, 3, 4, 5, 6, 7].map((item) => (
        <ProductCard />
      ))}
    </ImageList>
  );
}
