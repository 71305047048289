import React from 'react';
import { Typography, Grid } from '@mui/material';
export default function WhatIsPersonalized() {
  return (
    <Grid
      container
      sx={{
        bgcolor: 'rgba(30,27,45,1)',
        py: 4,
        justifyContent: { xs: 'center', md: 'flex-start' },
        textAlign: { xs: 'center', md: 'center' },
      }}
    >
      <Grid item xs={1}></Grid>
      <Grid item xs={10}>
        <Typography
          variant={'h4'}
          component='span'
          fontWeight='500'
          sx={{
            // color: 'rgba(252,252,252,1)',
            color: 'yellow',
            textAlign: { xs: 'center', md: 'center' },
            lineHeight: '1.26',
          }}
        >
          No Drag and Drop. No Inflexible Templates. No Moving Photos Around.
          {/* 💡 What is a Personalized Memory Book ?{" "}  💡 Our Personalized Memory Book is a unique
          keepsake that includes the child's name, avatar, photos, portfolio and documents their
          school adventures and personal milestones as they learn and grow.*/}
        </Typography>
        <Typography
          variant={'h4'}
          fontWeight='500'
          sx={{
            color: 'rgba(252,252,252,1)',
            textAlign: { xs: 'center', md: 'center' },
            lineHeight: '1.26',
            // mt: '10px',
          }}
        >
          Just share your photo collection and leave the rest to us.
          {/* 💡 What is a Personalized Memory Book ?{" "}  💡 Our Personalized Memory Book is a unique
          keepsake that includes the child's name, avatar, photos, portfolio and documents their
          school adventures and personal milestones as they learn and grow.*/}
        </Typography>
        <Typography
          variant={'h4'}
          fontWeight='500'
          sx={{
            color: 'rgba(252,252,252,1)',
            textAlign: { xs: 'center', md: 'center' },
            lineHeight: '1.26',
            // mt: '10px',
          }}
        >
          We will sort, filter, select photos and create a highly personalized keepsake.
          {/* 💡 What is a Personalized Memory Book ?{" "}  💡 Our Personalized Memory Book is a unique
          keepsake that includes the child's name, avatar, photos, portfolio and documents their
          school adventures and personal milestones as they learn and grow.*/}
        </Typography>
        <Typography
          variant={'h3'}
          fontWeight='900'
          component='span'
          sx={{ color: 'rgba(252,252,252,1)' }}
        ></Typography>
      </Grid>
      <Grid item xs={1}></Grid>
    </Grid>
  );
}
