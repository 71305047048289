import React from "react";
import {
  Typography,
  Grid,
  useMediaQuery,
  Link,
  Box,
  Stack,
  ImageList,
  Button,
  Card,
  CardContent,
  List,
  ListItemIcon,
  ListItemText,
  Icon,
} from "@mui/material";
import ResponsiveAppBar from "../../components/appBar";
import theme from "../../theme";
import Benjamin from "../../assets/benjamin.jpeg";
import CheckIcon from "@mui/icons-material/Check";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

export default function MatchingLettersToImages() {
  return (
    <Box sx={{ minHeight: "100vh" }}>
      <Grid
        container
        sx={{
          display: "flex",
          alignContent: "center",
          //   bgcolor: theme.palette.primary.black,
          height: "60px",
        }}
      >
        <Grid item xs={1} />
        <Grid item xs={10} sx={{ height: "24px", mt: "90px" }}>
          <Stack direction={"row"} gap="20px">
            <img
              src={Benjamin}
              style={{
                width: "50px",
                height: "50px",
                background: "brown",
                borderRadius: "50%",
                border: "solid 3px orange",
              }}
            />
            <Box>
              <Typography variant="h5">Benjamin Jones</Typography>{" "}
              <Typography
                variant="body2"
                sx={{ color: theme.palette.primary.lightText }}
              >
                Demo Preschool, Foster City
              </Typography>
            </Box>
          </Stack>
          <Typography variant="body1" sx={{ mt: "40px" }}>
            This worksheet helped Benjamin with the following learning
            objectives:
          </Typography>
          <Box sx={{ mt: "30px" }}>
            <LearningObj
              objective={
                "Demonstrate basic knowledge of one-to-one letter-sound correspondences by producing the primary sound for each consonant."
              }
            ></LearningObj>
          </Box>
          <Box sx={{ mt: "20px" }}>
            <LearningObj
              objective={
                "Recognize and name all upper and lowercase letters of the alphabet."
              }
            ></LearningObj>
          </Box>

          <Box sx={{ mt: "20px" }}>
            <LearningObj
              objective={
                "Demonstrate understanding of spoken words, syllables, and sounds (phonemes)."
              }
            ></LearningObj>
          </Box>

          <Box sx={{ mt: "40px" }} display="flex" justifyContent={"center"}>
            <Feedback />
          </Box>
          <Box
            sx={{ mt: "40px" }}
            display="flex"
            justifyContent={"center"}
            alignItems="flex-start"
            gap="12px"
          >
            <Icon>
              <CameraAltIcon sx={{ fontSize: "24px" }} />
            </Icon>
            <Typography
              variant="center"
              sx={{ fontSize: "14px", color: theme.palette.primary.lightText }}
            >
              Take a photo of the worksheet and we will add to your child’s
              portfolio
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </Box>
  );
}

const Feedback = () => {
  return (
    <Stack direction={"row"} gap={"30px"} alignItems="center">
      <Stack alignItems={"center"}>
        <Typography variant="h5" sx={{ fontSize: "24px" }}>
          😀
        </Typography>{" "}
        <Typography variant="body2" sx={{ fontSize: "12px", mt: "-4px" }}>
          Loved it
        </Typography>
      </Stack>
      <Stack alignItems={"center"}>
        <Typography variant="h5" sx={{ fontSize: "24px" }}>
          😊
        </Typography>{" "}
        <Typography variant="body2" sx={{ fontSize: "12px", mt: "-4px" }}>
          Liked it
        </Typography>
      </Stack>
      <Stack alignItems={"center"}>
        <Typography variant="h5" sx={{ fontSize: "24px" }}>
          😐
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "12px", mt: "-4px" }}>
          Meh
        </Typography>
      </Stack>
    </Stack>
  );
};

const LearningObj = ({ objective }) => {
  return (
    <Stack direction={"row"} gap="20px">
      <CheckIcon />
      <Typography
        variant="body1"
        sx={{
          fontSize: "14px",
          color: theme.palette.primary.lightText,
        }}
      >
        {objective}
      </Typography>
    </Stack>
  );
};
