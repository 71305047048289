export const bookSeries = [
  {
    id: 'myfamily',
    data: {
      header: {
        title: 'My Family Series',
        leftImage: 'http://placekitten.com/g/300/300',
        rightImage: 'http://placekitten.com/g/300/300',
      },
      books: [
        {
          image: 'http://placekitten.com/g/300/300',
          title: 'Family'
        },
        {
          image: 'http://placekitten.com/g/300/300',
          title: 'Family'
        }
      ]
    }
  },
  {
    id: 'grownup',
    data: {
      header: {
        title: 'Grown up Series',
        leftImage: 'http://placekitten.com/g/300/300',
        rightImage: 'http://placekitten.com/g/300/300',
      },
      books: [
        {
          image: 'http://placekitten.com/g/300/300',
          title: 'School'
        },
        {
          image: 'http://placekitten.com/g/300/300',
          title: 'School'
        }
      ]
    }
  },
  {
    id: 'mytoys',
    data: {
      header: {
        title: 'My Toys Series',
        leftImage: 'http://placekitten.com/g/300/300',
        rightImage: 'http://placekitten.com/g/300/300',
      },
      books: [
        {
          image: 'http://placekitten.com/g/300/300',
          title: 'School'
        },
        {
          image: 'http://placekitten.com/g/300/300',
          title: 'School'
        }
      ]
    }
  }
]