import React, { useState } from 'react'
import ResponsiveAppBar from '../../components/appBar'
import { Alert, Box, Button, CircularProgress, Grid, styled, Tab, Tabs, TextField, Typography } from '@mui/material'
import InfiniteTextSlider from '../../components/InfiniteTextSlider'
import VideoPlayerWithTabs from '../../components/VideoPlayerWithTab';
import { AnimatePresence, motion } from 'framer-motion';
import FlexibleWorkCard from '../../components/FlexibleWorkCard';
import { callPostAPI } from '../../helpers/api';
import CheckIcon from '@mui/icons-material/Check';
import ThankYouModal from '../../components/ThankYouModal';





export default function PhotoBookLandingPage() {




    return (
        <>
            <ResponsiveAppBar />
            <HeroSection />
            <VideoSection />
            {/* <VariationsTemplateDemo /> */}
            <FeatureListGrid />
            <ContactForm />
        </>
    )
}


const FeatureListGrid = () => {

    const featuresList = [
        {
            "title": "Effortlessly Ensure Every Child is Fairly Represented",
            url: "equalrepresentation.jpg",
            "description": "Our system makes it simple to track the photo count for each child. No need to manually count photos of each child or stress about leaving a child out."
        },
        {
            "title": "Get a complete Yearbook draft in 3 days",
            "url": "teacherrelaxing.jpeg",
            "description": "Complete your yearbook from start to finish in just 3 days. Volunteers save significant time as our process eliminates the grunt work of cropping photos and arranging layouts."
        },
        {
            "title": "Capture Event Memories",
            url: "kidscelebration.jpeg",
            "description": "Volunteers can record quotes or context around school events and upload audio or video recordings. We’ll convert them into text and include them on the relevant pages. Capture memories effortlessly, making the yearbook more meaningful and memorable."

        },
        {
            "title": "Maintain Creative Control",
            url: "abstract.jpeg",
            "description": "Our AI-powered tools give volunteers full creative freedom. Design custom templates easily and ensure your yearbook reflects your unique vision and style."
        },
        {
            "title": "Include Every Photo",
            "url": "polaroid.jpeg",
            "description": "Include every precious moment without wasting any photos regardless of number of pages in the book. Our service ensures that all photos are utilized effectively, personalizing each child’s yearbook. Every photo finds its place, capturing the essence of each memory."
        },
        {
            "title": "Flexible Photo Deadlines",
            url: "alarm.jpeg",
            "description": "Accept submissions up to one month before the deadline. We understand the challenges of gathering photos, so we offer extended deadlines to accommodate late submissions. This flexibility ensures that no moment is missed and all memories are included."
        },
        // {
        //     "title": "Include Photos from Last-Minute Events",
        //     "description": "Accept photo submissions up to one month before the yearbook is due. Unlike the current process with a 3-month photo deadline, our extended deadlines ensure that recent events and memories are included, so no moment is missed."
        // }
    ]

    return (
        <Grid container spacing={2} sx={{
            // marginTop: "5rem",
            padding: "1rem",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#f5f5f7",

        }} >
            {
                featuresList.map((feature, index) => {
                    return <FlexibleWorkCard
                        description={feature.description}
                        imageUrl={"sample1.png"}
                        title={feature.title}
                        index={index}
                        url={feature.url}
                    />
                })
            }

        </Grid>
    )
}


const VariationsTemplateDemo = () => {


    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }
    const StyledTab = styled(Tab)(({ theme }) => ({
        borderRadius: '20px',
        border: `1px solid ${theme.palette.divider}`,
        textTransform: 'none',
        minWidth: '300px',
        minHeight: 0,
        padding: theme.spacing(1, 2),
        '&.Mui-selected': {
            backgroundColor: "black",
            color: theme.palette.primary.contrastText,
        },
        margin: "1rem auto",
    }));

    return (
        <Grid container spacing={2} sx={{
            marginTop: "5rem", padding: "1rem",
            // height: "500px",
            alignItems: "center",
            justifyContent: "center",
        }} >
            <Grid item lg={4} xs={12} sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                left: 'auto',
                width: '100%',

            }}>
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{
                        borderRight: 1, borderColor: 'divider',
                        width: '100%',
                        alignSelf: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <StyledTab label="Item One" {...a11yProps(0)} />
                    <StyledTab label="Item Two" {...a11yProps(1)} />
                    <StyledTab label="Item Three" {...a11yProps(2)} />

                </Tabs>
            </Grid>
            <Grid item lg={7} xs={12} sx={{
                marginTop: "5rem"
            }}>
                <AnimatePresence mode='wait'>
                    <motion.img
                        key={value}  // This is crucial for AnimatePresence to work
                        src={'sample' + (value + 1) + '.png'}
                        style={{ height: "300px", width: "100%", objectFit: 'contain' }}
                        alt='sampleimage'
                        initial={{ y: 300, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -300, opacity: 0 }}
                        transition={{ type: "tween", stiffness: 600, damping: 20, }}

                    />
                </AnimatePresence>



            </Grid>
        </Grid>
    )
}

const HeroSection = () => {

    return (
        <Box sx={{ textAlign: 'center', marginTop: "180px" }}>
            <Typography variant="h1" fontWeight={'600'}>YEARBOOKS</Typography>
            <Typography variant="h3" fontWeight={'600'}>REIMAGINED WITH AI</Typography>
            <Button
                variant='contained'
                disableElevation
                sx={{
                    color: 'white',
                    background: 'black',
                    borderRadius: '10px',
                    fontWeight: '500',
                    fontSize: '18px',
                    border: 'solid 1px black',
                    px: "20px",
                    py: "10px",
                    minWidth: '225px',
                    '&:hover': {
                        background: 'black',
                    },
                    marginTop: "3rem"
                }}
            >
                Get Started
            </Button>
            <Box sx={{ marginTop: "5rem" }}>
                <InfiniteTextSlider text={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere."} />
            </Box>
        </Box>
    )
}


const VideoSection = () => {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const videoList = [
        {
            src: 'video1.mp4',
        },
        {
            src: 'video2.mp4',
        },
        {
            src: 'video3.mp4',
        },
        {
            src: 'video4.mp4',
        },
    ]

    return (
        <Box sx={{
            backgroundColor: "#f5f5f7",
            marginTop: "7rem",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            padding: "10rem 2rem"
        }}>
            {/* <video></video> */}
            <VideoPlayerWithTabs handleChange={handleChange} videoList={videoList} value={value} />
        </Box>
    )
}



const ContactForm = () => {

    const [formData, setFormData] = useState({
        schoolName: '',
        firstName: '',
        lastName: '',
        email: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = useState({
        type: "",
        show: false
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {

            console.log('Form submitted:', formData);
            await callPostAPI({ ...formData, type: "ELEMENTARY_YEARBOOK" }, '/demo/request');

            setIsLoading(false);
            setStatus({
                type: "SUCCESS",
                show: true
            })
            setFormData({
                schoolName: '',
                firstName: '',
                lastName: '',
                email: '',
            })
            //to hide the alert


        } catch (error) {
            console.log('Error submitting form:', error);
            setIsLoading(false);
        }
        // Here you would typically send the data to a server
    };

    return (
        <Grid container alignItems={'center'} justifyContent={'center'} sx={{
            margin: "2rem 0"
        }}>
            <ThankYouModal open={status.show} handleClose={() => setStatus({ type: "", show: false })} />
            <Grid item lg={6} >
                <Box component="form" onSubmit={handleSubmit} sx={{ width: "100%", margin: 'auto', mt: 4 }}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Student Registration
                    </Typography>

                    <TextField
                        fullWidth
                        margin="normal"
                        label="School Name"
                        name="schoolName"
                        value={formData.schoolName}
                        onChange={handleChange}
                        required
                    />

                    <Box sx={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}>

                        <TextField
                            margin="normal"
                            label="First Name"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            required
                            sx={{
                                width: "49%"
                            }}
                        />

                        <TextField

                            margin="normal"
                            label="Last Name"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            sx={{
                                width: "49%",
                                marginLeft: "1rem"
                            }}
                            required
                        />

                    </Box>

                    <TextField
                        fullWidth
                        margin="normal"
                        label="Email"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />

                    <Button type="submit" variant="contained" color="primary"
                        sx={{
                            mt: 5,
                            width: "150px",
                            padding: "0.8rem 2rem",
                            '&:hover': {
                                background: 'black',
                            },
                            marginBottom: "5rem"
                        }} >
                        {isLoading ? <CircularProgress size={20} color={'inherit'} /> : "Submit"}
                    </Button>

                </Box>
            </Grid>

        </Grid>
    );
}