import React from 'react';
import { Card, CardContent, Typography, Button, Box, Grid, CardMedia } from '@mui/material';
import { ArrowForward, Image } from '@mui/icons-material';
import { AnimatePresence, motion } from 'framer-motion';

const FlexibleWorkCard = ({ title, description, learnMoreText, url, index }) => {
    return (
        <AnimatePresence>

            <motion.div
                key={index}


            >
                <Grid Grid container sx={{ padding: "1rem", marginTop: "5rem" }}
                    justifyContent={'center'}
                    alignItems={'center'}
                    direction={index % 2 === 0 ? 'row' : "row-reverse"}>

                    <Grid item xs={12} md={5} sx={{ padding: "1rem 3rem" }} >
                        <Typography component="div" variant="h4">
                            {title}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div" sx={{ mt: "1rem" }}>
                            {description}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={5} sx={{ padding: "2rem", }} alignContent={'center'}>
                        <img alt='sampleimage' src={url ? url : 'sample3.png'} style={{
                            maxWidth: "100%",
                            borderRadius: "25px",
                            maxHeight: "500px",
                        }} />
                    </Grid>
                </Grid>
            </motion.div>
        </AnimatePresence>

    );
};

export default FlexibleWorkCard;