import * as React from 'react';
import {
    Typography,
    Box,
    useMediaQuery,
    useTheme,
    IconButton,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';


// const enrollmentCapacity = ['1-9', '10-19', '20-99', '100+', 'Other'];

export default function ThankYouModal({ open, handleClose }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={() => {
                    handleClose();
                }}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >

                <DialogTitle sx={{ px: '40px', py: '30px' }}>
                    <Typography id='modal-modal-title' variant='h4' component='h2' sx={{ textAlign: 'left' }}>
                        Thank You
                    </Typography>
                    <IconButton
                        edge='start'
                        color='inherit'
                        onClick={() => {
                            handleClose();
                        }}
                        aria-label='close'
                        sx={{
                            position: 'absolute',
                            right: '30px',
                            top: '15px',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ px: '40px', py: '30px' }}>
                    <Stack direction={'column'} alignItems='center' justifyContent='center'>
                        <Stack spacing={2}>
                            <Box>
                                <Typography
                                    id='modal-subtitle'
                                    variant='body1'
                                    sx={{ mb: 3, textAlign: 'left', }}
                                >
                                    Thank you for submitting your contact info we will contact you in some days.
                                </Typography>
                            </Box>

                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>
        </div>
    );
}
