import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { bookSeries } from "./constants";
import { Typography, Box, Divider, Stack } from "@mui/material";

import { useParams } from "react-router-dom";
import SmallCard from "../../components/SmallCard";
import ProductCard from "../../components/ProductCard";
import ResponsiveAppBar from "../../components/appBar";
import Books from "../../components/Books";
import BookTabs from "../../components/BookTabs";

export default function GrowUpBooks(props) {
  const params = useParams();
  const booksType = bookSeries.filter((e) => {
    return e.id == params.bookcat;
  })[0];

  return (
    <>
      <ResponsiveAppBar />

      <Box
        sx={{
          backgroundColor: "lemonchiffon",
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row" },
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            height: { marginTop: "5px", xs: "150px", sm: "200px", md: "250px" },
          }}
        ></Box>
        <Box
          sx={{ display: "flex", flexDirection: "column", textAlign: "center" }}
        >
          <Typography
            variant="h3"
            gutterBottom
            style={{ fontWeight: "bold", color: "red" }}
          ></Typography>

          {/* <Stack direction={"row"} sx={{ justifyContent: "center" }}>
            <SmallCard title={"My Family"} bookCategory={"myfamily"} />
            <SmallCard title={"When I grow up"} bookCategory={"grownup"} />
            <SmallCard title={"My Toys"} bookCategory={"mytoys"} />
          </Stack> */}
        </Box>
        <Box
          sx={{
            marginTop: "5px",
            display: { xs: "none", sm: "none", md: "block" },
            height: { xs: "150px", sm: "200px", md: "250px" },
          }}
        >
          {/* <img
            src={booksType.data.header.rightImage}
            style={{ objectFit: "cover", width: "100%", height: "100%" }}
          /> */}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Books />
      </Box>
    </>
  );
}
