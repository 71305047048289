import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { callGetAPI } from '../helpers/api';
import Spinner from '../components/Spinner';
import { Box, Stack, Typography } from '@mui/material';
import { getProfileUrl } from '../helpers/getProfileUrl';
import MyVityLogo from '../components/MyVityLogo';

const SchoolProfilePhotos = () => {
    const { schoolCode } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [childrenProfileInfo, setChildrenProfileInfo] = useState([]);
    const [schoolData, setSchoolData] = useState(null);
    const [childrenProfileInfoError, setChildrenProfileInfoError] = useState({ error: false, message: '' });

    useEffect(() => {
        const getSchoolChildrenProfilInfo = async () => {
            try {
                setIsLoading(true);
                const endpoint = `/schools/children-profile-info?schoolCode=${schoolCode}`;
                const response = await callGetAPI(endpoint);

                if (response?.success) {
                    setSchoolData(response.school);
                    setChildrenProfileInfo(response.childrenProfileInfo);
                } else {
                    setChildrenProfileInfoError((prev) => ({ ...prev, error: true, message: response.message }));
                }
                setIsLoading(false);
            } catch (error) {
                console.log('Error:', error);
                setChildrenProfileInfoError((prev) => ({ ...prev, error: true, message: 'Something went wrong while getting school profile photos, please try again after sometime!' }));
                setIsLoading(false);
            }
        }

        getSchoolChildrenProfilInfo();
    }, [schoolCode]);

    if (isLoading) {
        return (<Spinner open={true} />)
    }

    if (childrenProfileInfoError.error) {
        return (
            <Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: '120px'
                }}>
                    <Box>
                        <Box sx={{
                            height: '40px',
                            cursor: 'pointer',
                            textAlign: 'center'
                        }}>
                            <MyVityLogo color='black' />
                        </Box>
                        <Box sx={{
                            textAlign: 'center',
                            backgroundColor: 'rgba(0,0,0,0.03)',
                            py: '20px',
                            px: '30px',
                            borderRadius: '13px',
                            mt: '50px'
                        }}>
                            <Typography sx={{
                                fontSize: '24px',
                                fontWeight: 500
                            }}>{childrenProfileInfoError.message}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <>
            <Box>
                <Box sx={{
                    width: { xs: '100%', sm: '50%' },
                    px: { xs: '20px', sm: 0 },
                    mx: 'auto'
                }}>
                    <Stack sx={{ gap: '30px', mt: '50px' }}>
                        <Box sx={{
                            height: '40px',
                            cursor: 'pointer',
                            textAlign: 'center'
                        }}>
                            <MyVityLogo color='black' />
                        </Box>
                        {schoolData && (
                            <Box sx={{
                                textAlign: 'center',
                                backgroundColor: 'rgba(0,0,0,0.03)',
                                p: '20px',
                                borderRadius: '13px'
                            }}>
                                <Typography sx={{
                                    fontSize: { xs: '22px', sm: '26px', md: '30px' },
                                    fontWeight: 600
                                }}>{schoolData?.info?.name}, {schoolData?.address?.city}</Typography>
                            </Box>
                        )}
                        {childrenProfileInfo.length > 0 ? (
                            <Box>
                                <Stack sx={{
                                    width: '100%',
                                    gap: '10px'
                                }}>
                                    {childrenProfileInfo.map((childProfileInfo, index) => (
                                        <Box key={index}>
                                            <Box sx={{
                                                display: 'flex',
                                                gap: '20px',
                                                p: '10px'
                                            }}>
                                                <Box sx={{
                                                    width: { xs: '60px', sm: '75px' },
                                                    height: { xs: '60px', sm: '75px' },
                                                    borderRadius: '50%',
                                                    overflow: 'hidden'
                                                }}>
                                                    <Box
                                                        component={'img'}
                                                        src={getProfileUrl(childProfileInfo.rosterId)}
                                                        sx={{
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'cover',
                                                            display: 'block'
                                                        }}
                                                    />
                                                </Box>
                                                <Box sx={{
                                                    flexGrow: 1,
                                                    borderBottom: '1px solid rgba(0,0,0,0.1)',
                                                    pb: '30px'
                                                }}>
                                                    <Stack>
                                                        <Box>
                                                            <Typography sx={{
                                                                fontSize: { xs: '16px', sm: '20px' },
                                                                fontWeight: 500
                                                            }}>{childProfileInfo?.info?.firstName || ''} {childProfileInfo?.info?.middleName || ''} {childProfileInfo?.info?.lastName || ''}</Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography sx={{
                                                                fontSize: { xs: '14px', sm: '18px' }
                                                            }}>Gender : {childProfileInfo?.info?.gender === 0 ? 'Girl' : childProfileInfo?.info?.gender === 1 ? 'Boy' : "NA"}</Typography>
                                                        </Box>
                                                    </Stack>
                                                </Box>
                                            </Box>
                                        </Box>
                                    ))}
                                </Stack>
                            </Box>
                        ) : (
                            <Box sx={{ width: '100%', mt: '100px' }}>
                                <Typography sx={{
                                    fontSize: '20px',
                                    fontWeight: 500,
                                    textAlign: 'center'
                                }}>School profile photos list is empty!</Typography>
                            </Box>
                        )}
                    </Stack>
                </Box>
            </Box>
        </>
    )
}

export default SchoolProfilePhotos;