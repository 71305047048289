import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Button, Box, Stack, Typography } from "@mui/material";

export default function TextButtoWithChevron({ handleClick, title }) {
  return (
    <Stack direction={"row"} alignItems="center" onClick={handleClick}>
      <Button
        variant="'text"
        sx={{
          p: "0px",
          m: "0px",
          color: "rgba(43,101,178,1)",
          fontWeight: "500",
          border: "none",
          //   bgcolor: "green",
          ":hover": {
            bgcolor: "transparent",
            opacity: 1,
            textDecoration: "underline", // theme.palette.primary.main
            border: "none",
          },
        }}
        endIcon={
          <ChevronRightIcon
            sx={{
              color: "rgba(43,101,178,1)",
              position: "absolute",
              right: "-1.3rem",
              top: "0.3rem",
              bottom: "50%",
              width: "27px",
              height: "auto",
            }}
          />
        }
      >
        {title}
      </Button>
      {/* <ChevronRightIcon
        sx={{
          width: "36px",
          color: "rgba(43,101,178,1)",
          cursor: "pointer",
          p: "0px",
          m: "0px",
          //   background: "green",
        }}
      /> */}
    </Stack>
  );
}
