import React from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { AnimatePresence, motion } from 'framer-motion';

const StyledTabs = styled(Tabs)(({ theme }) => ({
    '& .MuiTabs-flexContainer': {
        gap: theme.spacing(2),
    },
    '& .MuiTabs-indicator': {
        display: 'none', // This removes the line under the tabs
    },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    borderRadius: '20px',
    border: `1px solid ${theme.palette.divider}`,
    textTransform: 'none',
    minWidth: 0,
    minHeight: 0,
    padding: theme.spacing(1, 2),
    '&.Mui-selected': {
        backgroundColor: "black",
        color: theme.palette.primary.contrastText,
    },
}));



const VideoPlayerWithTabs = ({ videoList, value, handleChange }) => {
    return (
        <>
            <AnimatePresence mode="wait">
                <motion.video
                    key={value}
                    src={videoList[value].src}
                    style={{
                        height: '500px',
                        width: '100%',
                        marginBottom: "2rem",

                    }}
                    loop
                    autoPlay
                    muted
                    playsInline
                    initial={{ x: 300, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -300, opacity: 0 }}
                    transition={{ type: "tween", stiffness: 600, damping: 20, }}
                />
            </AnimatePresence>
            <StyledTabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons={false}
                aria-label="rounded button tabs"
            >
                <StyledTab label="Personalize" />
                <StyledTab label="Draft" />
                <StyledTab label="Edit" />
            </StyledTabs>
        </>
    );
};

export default VideoPlayerWithTabs;