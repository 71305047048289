import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const PreschoolYearBooks = () => {
    const { schoolCode } = useParams();

    useEffect(() => {
        window.location.href = `https://products.makemyvity.com/school-memory-book-purchase-form/0lf9`;
    }, [schoolCode]);

    return <></>;
}

export default PreschoolYearBooks;