// import {makeStyles} from "@material-ui/core/styles";
import {styled} from '@mui/material/styles';

export const FooterImage = styled("img")(({theme}) => ({
  [theme.breakpoints.down("xs")]: {
    width: 120,
  },
}))

export const FooterTextLink = styled("a")(({theme}) => ({
  fontFamily: "Calibre-Regular",
  textDecoration: "none",
  fontSize: 20,
  color: "#F9F4F3",
  fontWeight: 500,
  [theme.breakpoints.down("xs")]: {
    fontSize: 18,
  },
}))

// export const useFooterStyles = makeStyles((theme) => ({
//     footerHeader: {
//         backgroundColor: "#27292B",
//         height: 100,
//         display: "flex",
//         justifyContent: "space-between",
//         alignItems: "center",
//         padding: "0px 40px",
//         [theme.breakpoints.down("xs")]: {
//             height: "auto",
//             padding: "40px 40px",
//             flexDirection: "column",
//         },
//     },
//     footerSection: {
//         // height: 100,
//         // margin: "auto",
//         backgroundColor: "#232426",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         padding: "20px 0px 0 0",
//         [theme.breakpoints.down("sm")]: {
//             height: "auto",
//             padding: "20px 20px",
//             // flexDirection: "column-reverse",
//         },
//     },
//     footerHeaderText: {
//         fontFamily: "Calibre-Regular",
//         fontSize: 20,
//         color: "#F9F4F3",
//         fontWeight: 500,
//         [theme.breakpoints.down("xs")]: {
//             marginTop: 10,
//             marginBottom: 10,
//         },
//     },
//     footerText: {
//         fontFamily: "Calibre-Regular",
//         fontSize: 20,
//         color: "#F9F4F3",
//         fontWeight: 500,
//         [theme.breakpoints.down("xs")]: {
//             fontSize: 18,
//         },
//     },
//     footerText1: {
//         fontFamily: "Calibre-Regular",
//         fontSize: 20,
//         color: "#F9F4F3",
//         fontWeight: 500,
//         [theme.breakpoints.down("xs")]: {
//             fontSize: 18,
//             margin: "15px 0px",
//         },
//     },
//     footerList: {
//         // height: 36,
//         maxWidth: "720px",
//         backgroundColor: "blue",
//
//         width: "100%",
//         display: "flex",
//         justifyContent: "space-between",
//
//         alignItems: "space-between",
//         listStyle: "none",
//         [theme.breakpoints.down("sm")]: {
//             margin: "15px 0px",
//         },
//     },
//     footerItem: {
//         // width: "25%",
//         display: "flex",
//         justifyContent: "space-between",
//         alignItems: "space-between",
//         cursor: "pointer",
//     },
//     appStore: {
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         [theme.breakpoints.down("xs")]: {
//             flexDirection: "column",
//             textAlign: "center",
//         },
//     },
//     appStoreImage: {},
//     flexCenter: {
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//     },
// }));
