import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    h2: {
      fontSize: 54,
      lineHeight: 1.26,
      fontWeight: 500,
      color: '#0C172A',
    },
    h3: {
      fontSize: 38,
      lineHeight: 1.26,
      fontWeight: 700,
      color: '#0C172A',
    },
    h4: {
      fontWeight: 700,
      color: '#0C172A',
    },
    h5: {
      fontWeight: 500,
      color: '#0C172A',
    },
    heroSubTitleLight: {
      fontSize: '24px',
      fontWeight: 400,
      color: 'rgb(90,90,90)',
      lineHeight: 1.35,
    },
    heroTitle: {
      fontSize: '64px',
      fontWeight: 700,
      lineHeight: 1.17,
    },
    sectionTitle: {
      fontSize: '45px',
      fontWeight: 700,
      lineHeight: 1.17,
    },
    body: {
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: 1.35,
      color: 'rgb(45,45,45)',
    },
  },
  get components() {
    return {
      MuiButton: {
        defaultProps: {
          disableElevation: true,
          disableFocusRipple: true,
          disableRipple: true,
        },
        styleOverrides: {
          outlined: {
            '&:hover': {
              backgroundColor: 'transparent',
              border: 'solid 2px rgb(45,45,45)',
            },
          },
        },
        variants: [
          {
            props: { variant: 'contained' },
            style: {
              border: `none`,
              color: 'white',
              background: 'rgba(0,0,0,1)',
              textTransform: 'none',
              height: '48px',
              paddingLeft: '32px',
              paddingRight: '32px',
              fontSize: '16px',
            },
            '&::hover': {
              background: 'rgba(54,45,45,1)',
            },
          },
          {
            props: { variant: 'outlined' },
            style: {
              border: `solid 2px rgb(45,45,45)`,
              color: 'black',
              textTransform: 'none',
              height: '48px',
              paddingLeft: '32px',
              paddingRight: '32px',
              fontSize: '16px',
            },
            '&:hover': {
              background: 'transparent',
              border: `solid 2px rgb(45,45,45)`,
            },
          },
        ],
      },
    };
  },
  palette: {
    primary: {
      main: '#3E4BA0',
      dark: '#313b7d',
      light: '#5664bd',
      black: '#0C172A',
      lightText: '#757575',
    },
  },
});

export default responsiveFontSizes(theme);
