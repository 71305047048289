import React from "react";
import { Button } from "@mui/material";

import ButtonBg from "../../../assets/flyers/buttonBg.png";

const ThemeButton = ({ variant, styleData, text, onClick, disabled, sx }) => {
  const outlined = (
    <Button
      variant={variant}
      sx={{
        textTransform: "none",
        borderRadius: "500px",
        fontSize: "18px",
        p: "5px 30px",
        border: `1px solid ${styleData.color.main}`,
        color: styleData.color.main,
        "&:hover": {
          border: `1px solid ${styleData.color.dark}`,
          backgroundColor: `${styleData.color.main}11`,
          boxShadow: `4px 4px 0px 0px ${styleData.color.dark}`,
        },
        ...sx,
      }}
      disabled={disabled}
      onClick={onClick}
    >
      <img
        src={ButtonBg}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
        }}
        alt="button"
      />
      {text}
    </Button>
  );

  if (variant === "outlined") return outlined;
  if (variant === "contained")
    return (
      <Button
        variant="contained"
        sx={{
          textTransform: "none",
          borderRadius: "500px",
          fontSize: "18px",
          p: "5px 30px",
          backgroundColor: styleData.color.main,
          "&:hover": {
            backgroundColor: styleData.color.main,
            boxShadow: `4px 4px 0px 0px ${styleData.color.dark}`,
          },
          ...sx,
        }}
        disabled={disabled}
        onClick={onClick}
      >
        <img
          src={ButtonBg}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
          }}
          alt="button"
        />
        {text}
      </Button>
    );

  return outlined;
};

export default ThemeButton;
