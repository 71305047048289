import React, { useRef, useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';

const InfiniteTextSlider = ({ text, duration = 35, className = '' }) => {
    const containerRef = useRef(null);
    const controls = useAnimation();
    const [contentWidth, setContentWidth] = useState(0);

    useEffect(() => {
        if (containerRef.current) {
            setContentWidth(containerRef.current.scrollWidth);
        }
    }, [text]);

    useEffect(() => {
        if (contentWidth > 0) {
            controls.start({
                x: [-contentWidth + 500, 0],
                transition: {
                    duration: duration,
                    repeat: Infinity,
                    repeatType: 'loop',
                    ease: 'linear',
                },
            });
        }
    }, [contentWidth, duration, controls]);

    return (
        <div style={{
            whiteSpace: 'nowrap',
            width: '100%',
            overflow: 'hidden',
            position: 'relative',
        }}>
            <motion.div
                ref={containerRef}
                className={`inline-block ${className}`}
                style={{
                    display: 'inline-block',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                }}
                animate={controls}
            >
                {text}
            </motion.div>
            <motion.div
                className={`inline-block ${className}`}
                style={{
                    display: 'inline-block',
                    whiteSpace: 'nowrap',
                    position: 'absolute',
                    left: 0,
                }}
                animate={controls}
            >
                {text}
            </motion.div>
        </div>
    );
};

export default InfiniteTextSlider;
