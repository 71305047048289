import React from 'react';
import { Route, Routes } from 'react-router-dom';

import GrowUpBooks from './pages/books/growUpBooks';
import Home from './pages/home/index';
import SelectRole from './pages/SelectRole';
import TracingLO from './pages/learningObjectives/TracingLO.js';
import MatchingLettersToImages from './pages/learningObjectives/MatchingLettersToImages';
import Flyer from './pages/flyer/Flyer';
import PhotoSharing from './pages/photosharing/PhotoSharing.jsx';
import PhotoReaction from './pages/photosharing/PhotoReaction.jsx';
import YearBooks from './pages/YearBooks.jsx';
import DemoRequest from './pages/DemoRequest';
// import YearBooksLandingPage from './pages/YearBooksLandingPage.jsx';
import BuildingKidzSchools from './pages/BuildingKidzSchools.jsx';
import SchoolProfilePhotos from './pages/SchoolProfilePhotos.jsx';
import UnsubscribeEmail from './pages/UnsubscribeEmail.jsx';
import PhotoBooksLandingPage from './pages/yearbooks/PhotoBookLandingPage.jsx';
import Yearbooks from './pages/yearbooks/Yearbooks.jsx';
import CampPhotoSharing from './pages/photosharing/index.js';
import PreschoolYearBooks from './pages/PreschoolYearbooks.jsx';

const RouteProvider = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/photosharing/camps' element={<CampPhotoSharing />} />
      <Route path='/schools/bk' element={<BuildingKidzSchools />} />
      <Route path='/year-books/:schoolCode' element={<YearBooks />} />
      <Route path='/school-profile-photos/:schoolCode' element={<SchoolProfilePhotos />} />
      {/* <Route path='/yearbooks' element={<YearBooksLandingPage />} /> */}
      {/* <Route path='/yearbooks' element={<Yearbooks />} /> */}
      <Route path='/yearbooks' element={<PhotoBooksLandingPage />} />
      <Route path='/preschool-yearbooks/66cfb5dfde1704cb08c0f614' element={<PreschoolYearBooks />} />
      <Route path='/unsubscribe-email' element={<UnsubscribeEmail />} />

      <Route path='/school/demo-request' element={<DemoRequest />} />
      <Route path='/flyer' element={<Flyer />} />
      <Route path='/books/grow-up' element={<GrowUpBooks />} />
      <Route path='/select-role' element={<SelectRole />} />
      <Route path='/parent-photo-sharing' element={<PhotoSharing />} />
      <Route path='/parent-photo-reaction' element={<PhotoReaction />} />
      <Route path='/ws-0001-schlgr01-schl' element={<TracingLO />} />
      <Route path='/ws-0002-schlgr01-schl' element={<MatchingLettersToImages />} />
    </Routes>
  );
};

export default RouteProvider;
