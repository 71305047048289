// Wait till image is loaded
export const imageLoadPromise = (imgUrl) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      resolve(image);
    };
    image.src = imgUrl;
  });

// Convert base64 to blob
export const base64toBlob = (base64Data) => {
  const base64Binary = atob(base64Data);
  const arrayBuffer = new ArrayBuffer(base64Binary.length);
  const imageByteArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < base64Binary.length; i++) {
    imageByteArray[i] = base64Binary.charCodeAt(i);
  }
  const imageBlob = new Blob([arrayBuffer], { type: "image/png" });
  return imageBlob;
};
