import React, { useState, useRef } from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import RequestDialog from '../../../components/RequestDialog';
import More1 from '../../../assets/memoryBookSlider/and-more-1.webp';
import More2 from '../../../assets/memoryBookSlider/and-more-2.webp';
import Graduation from '../../../assets/memoryBookSlider/cover-graduation.webp';
import PrekClass from '../../../assets/memoryBookSlider/prekClass.webp';

import Note1 from '../../../assets/memoryBookSlider/personal-note-1.webp';
import Note2 from '../../../assets/memoryBookSlider/personal-note-2.webp';
import Timeline1 from '../../../assets/memoryBookSlider/timeline-1.webp';
import Timeline2 from '../../../assets/memoryBookSlider/timeline-2.webp';
import Portfolio1 from '../../../assets/memoryBookSlider/portfolio-1.webp';
import Portfolio2 from '../../../assets/memoryBookSlider/portfolio-2.webp';
import Home1 from '../../../assets/memoryBookSlider/home-1.webp';
import Home2 from '../../../assets/memoryBookSlider/home-2.webp';
import School1 from '../../../assets/memoryBookSlider/school-1.webp';
import School2 from '../../../assets/memoryBookSlider/school-2.webp';
import Personalized1 from '../../../assets/memoryBookSlider/personalized-1.webp';
import Personalized2 from '../../../assets/memoryBookSlider/personalized-2.webp';
import Automated1 from '../../../assets/memoryBookSlider/automated-1.webp';
import Automated2 from '../../../assets/memoryBookSlider/automated-2.webp';

export default function HorizontalScroll({
  data = [
    {
      id: '91',
      type: 'image',
      url: Graduation,
    },
    {
      id: '12',
      type: 'image',
      url: Personalized2,
    },
    // {
    //   id: '92',
    //   type: 'image',
    //   url: PrekClass,
    // },
    {
      id: '11',
      type: 'image',
      url: Personalized1,
    },
    {
      id: '62',
      type: 'image',
      url: Timeline2,
    },
    // {
    //   id: '12',
    //   type: 'image',
    //   url: Personalized2,
    // },
    {
      id: '21',
      type: 'image',
      url: Automated1,
    },
    {
      id: '22',
      type: 'image',
      url: Automated2,
    },
    {
      id: '31',
      type: 'image',
      url: School1,
    },
    {
      id: '32',
      type: 'image',
      url: School2,
    },
    // {
    //   id: '41',
    //   type: 'image',
    //   url: Home1,
    // },
    // {
    //   id: '42',
    //   type: 'image',
    //   url: Home2,
    // },

    {
      id: '51',
      type: 'image',
      url: Portfolio1,
    },
    {
      id: '52',
      type: 'image',
      url: Portfolio2,
    },
    // {
    //   id: '61',
    //   type: 'image',
    //   url: Timeline1,
    // },
    // {
    //   id: '62',
    //   type: 'image',
    //   url: Timeline2,
    // },
    // {
    //   id: '71',
    //   type: 'image',
    //   url: Note1,
    // },
    // {
    //   id: '72',
    //   type: 'image',
    //   url: Note2,
    // },
    // {
    //   id: '81',
    //   type: 'image',
    //   url: More1,
    // },
    {
      id: '92',
      type: 'image',
      url: PrekClass,
    },
    {
      id: '82',
      type: 'image',
      url: More2,
    },
  ],
}) {
  const ref = useRef();
  const [showRequest, setShowRequest] = useState({
    status: false,
    title: '',
    text: '',
    type: '',
  });

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  return (
    <>
      <Grid
        container
        alignItems={'center'}
        justifyContent='center'
        sx={{
          pt: '60px',
          backgroundImage: 'linear-gradient(260.92deg,#f5f9ff 12.21%,rgba(237,241,247,.2) 78.08%)',
        }}
      >
        <Grid item xs={1} md={2}></Grid>
        <Grid
          item
          xs={10}
          md={8}
          sx={{
            filter: 'drop-shadow(2px 2px 2px rgba(0,0,0,0.5))',
          }}
        >
          <Slider {...settings} ref={ref}>
            {data.map((item, index) => {
              return (
                <Box
                  key={index}
                  onClick={(e) =>
                    setShowRequest({
                      status: true,
                      type: 'sample',
                      title: 'Sample Personalized Yearbook',
                      text: 'Please enter your email address below and we will reach out to you to ship a Personalized Memory Book.',
                    })
                  }
                  sx={{ cursor: 'pointer' }}
                >
                  <BookSlide data={item} />
                </Box>
              );
            })}
          </Slider>
        </Grid>
        <Grid item xs={1} md={2}></Grid>
      </Grid>
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems='center'
        justifyContent={'center'}
        gap={4}
        sx={{
          pt: '30px',
          pb: '60px',
          backgroundImage: 'linear-gradient(260.92deg,#f5f9ff 12.21%,rgba(237,241,247,.2) 78.08%)',
          width: '100%',
        }}
      >
        <ArrowIcon dir='left' handleArrow={(e) => ref.current.slickPrev()} />
        <ArrowIcon dir='right' handleArrow={(e) => ref.current.slickNext()} />
      </Box>
      <RequestDialog
        open={showRequest.status}
        handleClose={(e) => setShowRequest(false)}
        type={showRequest.type}
        title={showRequest.title}
        text={showRequest.text}
      />
    </>
  );
}

const ArrowIcon = ({ dir, handleArrow, display }) => {
  return (
    <Box
      display={'flex'}
      justifyContent='center'
      alignItems={'center'}
      sx={{
        width: '50px',
        height: '50px',
        cursor: 'pointer',
        border: 'solid 1px #757575',
        borderRadius: '4px',
        textAlign: 'center',
      }}
      onClick={(e) => handleArrow(dir)}
    >
      {dir == 'left' ? (
        <KeyboardArrowLeftIcon sx={{ fontSize: '40px' }} />
      ) : (
        <KeyboardArrowRightIcon sx={{ fontSize: '40px' }} />
      )}
    </Box>
  );
};

const BookSlide = ({ data }) => {
  const handleGetSample = () => {};
  const image = () => {
    return (
      <Grid container gap={0}>
        <Grid item xs={12}>
          <img
            src={data.url}
            style={{
              width: '100%',
              height: '100%',
              aspectRatio: 1,
              padding: 0,
              margin: 0,
            }}
          />
        </Grid>
      </Grid>
    );
  };
  const text = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              width: '100%',
              aspectRatio: 1,
              pt: '100%',
              // height: "100px",
              background: 'white',
              position: 'relative',
            }}
          >
            <Typography
              align='center'
              variant='h4'
              fontWeight={900}
              sx={{
                position: 'absolute',
                top: { xs: '12%', sm: '18%' },
                width: '100%',
                px: '20px',
                left: '50%',
                transform: 'translate(-50%)',
              }}
            >
              {data.title}
            </Typography>
            <Typography
              align='center'
              variant='h6'
              fontWeight={400}
              lineHeight={1.35}
              sx={{
                position: 'absolute',
                top: { xs: '27%', sm: '32%' },
                width: '100%',
                left: '50%',
                transform: 'translate(-50%)',
                px: { xs: '16px', sm: '30px' },
              }}
            >
              {data.desc}
            </Typography>
            <Button
              variant='contained'
              sx={{
                position: 'absolute',
                bottom: '5%',
                background: 'black',
                color: 'white',
                left: '50%',
                transform: 'translate(-50%)',
                '&:hover': {
                  transition: '0.2s',
                  transform: 'translate(-50%)',
                  background: 'black',
                },
              }}
            >
              GET SAMPLE
            </Button>
          </Box>
        </Grid>
      </Grid>
    );
  };
  switch (data.type) {
    case 'image':
      return image();

    case 'text':
      return text();

    default:
      break;
  }
};
