import React from "react";
import { Box, Stack, Typography } from "@mui/material";

const InfoItem = ({ icon, titleText, subTitleText }) => {
  return (
    <Stack
      direction={"row"}
      gap={2}
      sx={{
        borderRadius: "20px",
        py: "15px",
      }}
    >
      {/* Icon */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bgcolor: "white",
          borderRadius: "50%",
          width: "56px",
          height: "56px",
          aspectRatio: "1 / 1",
        }}
      >
        {icon}
      </Box>
      {/* Text */}
      <Stack justifyContent={"center"}>
        <Typography variant="h6">{titleText}</Typography>
        <Typography
          variant="body2"
          fontWeight={500}
          sx={{
            opacity: 0.8,
          }}
        >
          {subTitleText}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default InfoItem;
