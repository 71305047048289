import HalloweenBG from "../../assets/flyers/Halloween/bg.png";
import HalloweenFlyer1Img from "../../assets/flyers/Halloween/halloweenFlyer1.jpg";
import HalloweenFlyer2Img from "../../assets/flyers/Halloween/halloweenFlyer2.jpg";
import HalloweenFlyer3Img from "../../assets/flyers/Halloween/halloweenFlyer3.jpg";
import HalloweenFlyer4Img from "../../assets/flyers/Halloween/halloweenFlyer4.jpg";

export const createStyleData = () => {
  return {
    title: "Happy Halloween",
    instruction: "Select the flyer you wish to personalise",
    titleFontFamily: "Skia",
    color: {
      main: "#E06B2A",
      light: "#FFF7F3",
      dark: "#8B3E13",
      inputBg: "#f7f7f7",
    },
    backgroundImg: HalloweenBG,
    loadFontFamiles: [
      { name: "Skia", file: "Skia.ttf" },
      { name: "Henny Penny", file: "HennyPenny-Regular.ttf" },
      { name: "Eater", file: "Eater-Regular.ttf" },
      { name: "Poppins-SemiBold", file: "Poppins-SemiBold.ttf" },
      {
        name: "BlackAndWhitePicture",
        file: "BlackAndWhitePicture-Regular.ttf",
      },
    ],
  };
};

export const createFlyerData = (type) => {
  const data = {
    [`${type}Flyer1`]: [
      // image
      {
        type: "image",
        url: HalloweenFlyer1Img,
        boundingBoxCords: {
          x: 0,
          y: 0,
          width: 1,
          height: 1,
        },
      },
      // name
      {
        type: "text",
        id: "School-name",
        placeholder: "Your School Name",
        value: "",
        boundingBoxCords: {
          x: 0.1482,
          y: 0.0364,
          width: 0.6941,
          height: 0.0718,
        },
        style: {
          fillStyle: "#000000",
          font: "64px Henny Penny",
        },
      },
      // date
      {
        type: "date",
        id: "Event-date",
        placeholder: "Event Date",
        value: "",
        boundingBoxCords: {
          x: 0.2035,
          y: 0.904,
          width: 0.2976,
          height: 0.0664,
        },
        style: {
          fillStyle: "black",
          font: "33px Poppins-SemiBold",
        },
      },
      // time
      {
        type: "time",
        id: "Event-time",
        placeholder: "Event Time",
        value: "",
        boundingBoxCords: {
          x: 0.5012,
          y: 0.907,
          width: 0.2976,
          height: 0.0664,
        },
        style: {
          fillStyle: "black",
          font: "33px Poppins-SemiBold",
        },
      },
    ],
    [`${type}Flyer2`]: [
      {
        type: "image",
        url: HalloweenFlyer2Img,
        boundingBoxCords: {
          x: 0,
          y: 0,
          width: 1,
          height: 1,
        },
      },
      {
        type: "text",
        id: "School-name",
        placeholder: "Your School Name",
        value: "",
        boundingBoxCords: {
          x: 0.1541,
          y: 0.0155,
          width: 0.6929,
          height: 0.0618,
        },
        style: {
          fillStyle: "#d2581e",
          font: "64px Eater",
        },
      },
      {
        type: "date",
        id: "Event-date",
        placeholder: "Event Date",
        value: "",
        boundingBoxCords: {
          x: 0.2329,
          y: 0.735,
          width: 0.2471,
          height: 0.0255,
        },
        style: {
          fillStyle: "black",
          font: "33px Poppins-SemiBold",
        },
      },
      {
        type: "time",
        id: "Event-time",
        placeholder: "Event Time",
        value: "",
        boundingBoxCords: {
          x: 0.5024,
          y: 0.71,
          width: 0.2847,
          height: 0.0818,
        },
        style: {
          fillStyle: "black",
          font: "33px Poppins-SemiBold",
        },
      },
    ],
    [`${type}Flyer3`]: [
      {
        type: "image",
        url: HalloweenFlyer3Img,
        boundingBoxCords: {
          x: 0,
          y: 0,
          width: 1,
          height: 1,
        },
      },
      {
        type: "text",
        id: "School-name",
        placeholder: "Your School Name",
        value: "",
        boundingBoxCords: {
          x: 0.1059,
          y: 0.0291,
          width: 0.8024,
          height: 0.0891,
        },
        style: {
          fillStyle: "white",
          font: "64px Eater",
        },
      },
      {
        type: "text",
        id: "Event-bags",
        placeholder: "Bags Count",
        value: "",
        boundingBoxCords: {
          x: 0.2282,
          y: 0.889,
          width: 0.2459,
          height: 0.0564,
        },
        style: {
          fillStyle: "black",
          font: "33px Poppins-SemiBold",
        },
      },
      {
        type: "date",
        id: "Event-date",
        placeholder: "Event Date",
        value: "",
        boundingBoxCords: {
          x: 0.5235,
          y: 0.884,
          width: 0.2459,
          height: 0.0564,
        },
        style: {
          fillStyle: "black",
          font: "33px Poppins-SemiBold",
        },
      },
    ],
    [`${type}Flyer4`]: [
      {
        type: "image",
        url: HalloweenFlyer4Img,
        boundingBoxCords: {
          x: 0,
          y: 0,
          width: 1,
          height: 1,
        },
      },
      {
        type: "text",
        id: "School-name",
        placeholder: "Your School Name",
        value: "",
        boundingBoxCords: {
          x: 0.1247,
          y: 0.0282,
          width: 0.7518,
          height: 0.0691,
        },
        style: {
          fillStyle: "black",
          font: "64px BlackAndWhitePicture",
        },
      },
      {
        type: "date",
        id: "Event-date",
        placeholder: "Event Date",
        value: "",
        boundingBoxCords: {
          x: 0.2341,
          y: 0.869,
          width: 0.2824,
          height: 0.0873,
        },
        style: {
          fillStyle: "black",
          font: "33px Poppins-SemiBold",
        },
      },
      {
        type: "time",
        id: "Event-time",
        placeholder: "Event Time",
        value: "",
        boundingBoxCords: {
          x: 0.5165,
          y: 0.87,
          width: 0.2624,
          height: 0.0873,
        },
        style: {
          fillStyle: "black",
          font: "33px Poppins-SemiBold",
        },
      },
    ],
  };

  return data;
};

export const createFieldsData = () => {
  const data = [
    {
      type: "text",
      id: "School-name",
      placeholder: "Your School Name",
      value: "",
      icon: "school",
    },
    {
      type: "text",
      id: "Event-bags",
      placeholder: "Bags Count",
      value: "",
      icon: "bag",
    },
    {
      type: "date",
      id: "Event-date",
      placeholder: "Event Date",
      value: "",
      icon: "date",
    },
    {
      type: "time",
      id: "Event-time",
      placeholder: "Event Time",
      value: "",
      icon: "time",
    },
  ];
  return data;
};
