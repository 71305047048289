import React from "react";
import { Box, Dialog, DialogContent, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { Close } from "@mui/icons-material";

const ThankYouModal = ({ open, handleClose, styleData }) => {
  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiBackdrop-root": {
          backdropFilter: "blur(2px)",
        },
      }}
      PaperProps={{
        sx: {
          overflowY: "visible",
          borderRadius: "30px",
          margin: "45px",
        },
      }}
    >
      <DialogContent
        sx={{
          p: "40px",
          borderRadius: "30px",
        }}
      >
        {/* Close */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: "-40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            width: "30px",
            height: "30px",
            borderRadius: "100px",
          }}
        >
          <Close
            onClick={handleClose}
            sx={{
              cursor: "pointer",
              color: styleData.color.main,
            }}
          />
        </Box>
        <Stack gap={2}>
          <Typography
            textAlign={"center"}
            variant="h2"
            sx={{
              fontWeight: 700,
              fontFamily: styleData.titleFontFamily,
              color: styleData.color.main,
              letterSpacing: "1px",
              lineHeight: "normal",
            }}
          >
            Thank You
          </Typography>
          <Typography
            textAlign={"center"}
            variant="body1"
            sx={{
              color: "#121345",
            }}
          >
            You should get your customised flyer in your email.
          </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ThankYouModal;
