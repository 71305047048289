import React from "react";
import {
  Box,
  Stack,
  Typography,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";

export default function TextFieldWithTitle({
  width,
  maxWidth = 600,
  height = "45px",
  field,
  label,
  value,
  handleInput,
  endAdornment,
  placeholder,
}) {
  const inputProps = {
    style: {
      display: "flex",
      paddingBottom: "16px",
      height: height,
      width: width,
      maxWidth: maxWidth,
    },
    disableUnderline: true,
    endAdornment: endAdornment,
  };
  return (
    <Stack sx={{ width: "100%" }}>
      <Typography
        variant="body1"
        fontWeight={500}
        gutterBottom
        sx={{ color: "#757575" }}
      >
        {label}
      </Typography>
      <TextField
        width={width}
        variant="filled"
        size="small"
        placeholder={placeholder}
        InputProps={inputProps}
        value={value}
        onChange={(e) => {
          handleInput(field, e.target.value);
        }}
      ></TextField>
    </Stack>
  );
}
