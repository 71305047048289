import { Download, School } from '@mui/icons-material';
import {
  Alert,
  Avatar,
  Box,
  // Button,
  Container,
  Grid,
  IconButton,
  Portal,
  Snackbar,
  Stack,
  // TextField,
  Typography,
  colors,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Axios } from '../../helpers/axios';

import Spinner from '../../components/Spinner';
import MediaPreview from './component/MediaPreview';
import PhotoModal from './component/PhotoModal';
import { getProfileUrl } from '../../helpers/getProfileUrl';

const PhotoSharing = () => {
  const navigate = useNavigate();

  const [photos, setPhotos] = useState(null);
  const [schoolData, setSchoolData] = useState(null);
  // const [commentData, setCommentData] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currPhotoId, setModalCurrPhotoIdx] = useState(0);
  const [photoModalOpen, setPhotoModalOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const [alert, setAlert] = useState({ message: '', type: 'info' });

  const handleDownload = async (url, name) => {
    const image = await fetch(url);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    const link = document.createElement('a');
    link.href = imageURL;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handlePhotoModal = (isOpen) => {
    setPhotoModalOpen(isOpen);
  };

  const openPhotoModal = (photoId) => {
    setModalCurrPhotoIdx(photoId);
    handlePhotoModal(true);
  };

  // const submitComment = async () => {
  //   try {
  //     setIsLoading(true);
  //     const res = await Axios.post('/shared-photos-comment', {
  //       postId: searchParams.get('pgi'),
  //       memberId: searchParams.get('mid'),
  //       comment: commentData,
  //     });
  //     if (res.success) {
  //       setIsLoading(false);
  //       setAlert({ message: 'Comment sent.', type: 'success' });
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     setAlert({ message: 'Something gone wrong!!', type: 'error' });
  //   }
  //   setIsLoading(false);
  //   setCommentData('');
  // };

  const handleCloseErrorPopup = () => {
    setAlert({ message: '', type: 'info' });
  };

  // Fetch data
  useEffect(() => {
    const fetchData = async () => {
      const memberId = searchParams.get('mid');
      const pgi = searchParams.get('pgi');
      const schoolId = searchParams.get('sid');
      setIsLoading(true);
      if (memberId && pgi && schoolId) {
        // Save user view
        try {
          const res = await Axios.post('/web-parent-photo-sharing', {
            memberId,
            pgi,
            schoolId,
          });
          if (res.success) {
            setPhotos(res.list);
            setSchoolData(res.schoolInfo);
          }

          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      } else {
        // Redirect
        navigate('/');
        setIsLoading(false);
      }
    };

    fetchData();
  }, [navigate, searchParams]);

  return (
    <Box sx={{ backgroundColor: '#f7f7f7', minHeight: '100vh' }}>
      <Portal>
        <Snackbar
          open={!!alert.message}
          autoHideDuration={6000}
          onClose={() => {
            handleCloseErrorPopup();
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert onClose={handleCloseErrorPopup} severity={alert.type} sx={{ width: '100%' }}>
            {alert.message}
          </Alert>
        </Snackbar>
      </Portal>
      {/* Spinner */}
      <Spinner open={isLoading} />
      {/* Photo modal */}
      <PhotoModal
        open={photoModalOpen}
        handleClose={() => {
          handlePhotoModal(false);
        }}
        photosList={photos || []}
        currPhotoId={currPhotoId}
      />
      <Container maxWidth='sm'>
        {/* Logo */}
        <Stack
          alignItems={'center'}
          sx={{
            py: '15px',
          }}
        >
          {schoolData?.logo ? (
            <Box
              component={'img'}
              src={schoolData.logo}
              alt='school'
              sx={{
                borderRadius: '4px',
                height: '90px',
              }}
            />
          ) : (
            <Avatar variant='rounded' sx={{ width: '64px', height: '63px' }}>
              <School />
            </Avatar>
          )}
        </Stack>
        {/* Caption */}
        {photos && photos.length > 0 && photos[0]?.shd?.cap && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Stack
              sx={{
                maxWidth: '400px',
                border: '1px solid #929292',
                borderRadius: '10px',
                p: '10px',
                backgroundColor: '#fff',
                gap: '15px',
              }}
            >
              <Stack direction={'row'} sx={{ gap: '15px', alignItems: 'center' }}>
                <Avatar
                  alt='Profile'
                  src={getProfileUrl(photos[0]?.shd?.by?.uid)}
                  variant='circular'
                  sx={{ width: '50px', height: '50px' }}
                />
                <Box>
                  <Typography variant='body1' sx={{ fontWeight: 500 }}>
                    {photos[0]?.shd?.by?.firstName} {photos[0]?.shd?.by?.lastName}
                  </Typography>
                </Box>
              </Stack>
              <Box>
                <Typography variant='body1' sx={{ color: '#929292' }}>
                  {photos[0]?.shd?.cap}
                </Typography>
              </Box>
            </Stack>
          </Box>
        )}
        {/* Photo List */}
        <Grid
          container
          spacing={1.5}
          sx={{
            mt: '15px',
            pb: '25px',
          }}
        >
          {photos &&
            (photos.length > 0 ? (
              photos.map((photo, idx) => {
                return (
                  <Grid
                    id={photo.pid}
                    item
                    sm={12}
                    key={`photo-${idx}`}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        position: 'relative',
                        maxWidth: '400px',
                        aspectRatio: '1/1',
                        height: '100%',
                        width: '100%',
                        bgcolor: colors.grey[200],
                        borderRadius: '8px',
                        overflow: 'hidden',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => {
                        // Don't start video on click
                        e.preventDefault();
                        openPhotoModal(photo.pid);
                      }}
                    >
                      {/* Download */}
                      <IconButton
                        sx={{
                          display: 'none',
                          p: 0,
                          position: 'absolute',
                          bottom: '10px',
                          right: '10px',
                          bgcolor: 'white',
                          borderRadius: '100%',
                          width: '40px',
                          aspectRatio: '1/1',
                          // display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                          color: 'black',
                          ':hover': {
                            bgcolor: 'white',
                          },
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDownload(photo.url, 'Photo.jpg')
                            .then(() => {
                              console.log('Photo Downloaded');
                            })
                            .catch((error) => {
                              console.log('Error in downloading');
                            });
                        }}
                      >
                        <Download />
                      </IconButton>
                      {/* Media */}
                      <MediaPreview url={photo.url} type={photo.med} objectFit={'cover'} />
                    </Box>
                  </Grid>
                );
              })
            ) : (
              <Grid item xs={12}>
                <Typography variant='body2' textAlign={'center'}>
                  No shared photos
                </Typography>
              </Grid>
            ))}
        </Grid>
        {/* Comment field */}
        {/* <TextField
          value={commentData}
          sx={{
            mb: '20px',
            width: '100%',
          }}
          placeholder='Send Message'
          multiline
          rows={3}
          onChange={(e) => {
            setCommentData(e.target.value);
          }}
        /> */}
        {/* Submit */}
        {/* <Button
          // Disable if photo list is empty
          disabled={!commentData || !Array.isArray(photos)}
          sx={{
            mb: '20px',
            width: '100%',
          }}
          variant='contained'
          onClick={submitComment}
        >
          Submit
        </Button> */}
      </Container>
    </Box>
  );
};

export default PhotoSharing;
