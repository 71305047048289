import React from "react";
import { useNavigate } from "react-router-dom";

export default function MyVityLogo({ color = 'black' }) {
  const Navigate = useNavigate();

  return (
    <img
      src={color === 'light' ? process.env.PUBLIC_URL + "/logoWhite.png" : process.env.PUBLIC_URL + "/logoBlack.png"}
      onClick={(e) => Navigate("/")}
      style={{ height: "100%" }}
      alt='MyVity Logo'
    ></img>
  );
}
