import { Grid, Paper } from "@mui/material";
import * as React from "react";

export default function ProductCard(props) {
  return (
    <Grid key={0} sx={{}} item>
      <Paper
        sx={{
          height: 350,
          // maxWidth: "350px",
          backgroundColor: "black",
        }}
      />
    </Grid>
  );
}
