import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Axios } from '../../helpers/axios';
import { Box, Stack, Typography } from '@mui/material';
import Spinner from '../../components/Spinner';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const PhotoReaction = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const handleReaction = async () => {
            const rosterId = searchParams.get('rid');
            const pgi = searchParams.get('pgi');
            const reaction = searchParams.get('rxn');
            const memberId = searchParams.get('mid');

            setIsLoading(true);
            if (rosterId && pgi && reaction && memberId) {
                try {
                    const response = await Axios.post('/shared-photos-reaction', { rosterId, pgi, reaction, memberId });
                    if (response.success) {
                        setIsLoading(false);
                    }
                } catch (error) {
                    navigate('/');
                    setIsLoading(false);
                }
            } else {
                navigate('/');
                setIsLoading(false);
            }
        };

        handleReaction();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Box>
                <Spinner open={isLoading} />
                {!isLoading && (
                    <Stack sx={{
                        height: "100vh",
                        gap: "15px",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center"
                    }}>
                        <Box>
                            <TaskAltIcon sx={{ fontSize: '40px', color: "#54ae32" }} />
                        </Box>
                        <Box>
                            <Typography variant='h4' sx={{ fontWeight: 500 }}>Thank you for your feedback!</Typography>
                            <Typography variant='h4' sx={{ fontWeight: 500 }}>We will let the school know.</Typography>
                        </Box>
                    </Stack>
                )}
            </Box>
        </>
    )
}

export default PhotoReaction;