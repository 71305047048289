import * as React from 'react';
import {
  Typography,
  Button,
  Box,
  FormControl,
  useMediaQuery,
  Radio,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  useTheme,
  IconButton,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import TextFieldWithTitle from './TextFieldWithTitle';
import { callPostAPI } from '../helpers/api';
import Spinner from './Spinner';

// const enrollmentCapacity = ['1-9', '10-19', '20-99', '100+', 'Other'];

export default function DemoModal({ open, handleClose }) {
  const matches = useMediaQuery('(max-width:850px)');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [formData, setFormData] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    contact: '',
    schoolName: '',
    reason: '',
    capacity: '',
  });
  const [isRequesting, setIsRequesting] = React.useState(false);

  const handleChange = (type, value) => {
    setFormData({ ...formData, [type]: value });
  };

  const handleSubmit = async () => {
    setIsRequesting(true);
    await callPostAPI(formData, '/demo/request');
    handleClose();
    setIsRequesting(false);
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      contact: '',
      schoolName: '',
      reason: '',
      capacity: '',
    });
  };

  const isDisabled = () => {
    if (!formData.firstName || !formData.lastName || !formData.email || !formData.reason) {
      return true;
    } else {
      return false;
    }
  };

  const clearFormData = () => {
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      contact: '',
      schoolName: '',
      reason: '',
      capacity: '',
    });
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => {
          handleClose();
          clearFormData();
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Spinner open={isRequesting} />
        <IconButton
          edge='start'
          color='inherit'
          onClick={() => {
            handleClose();
            clearFormData();
          }}
          aria-label='close'
          sx={{
            position: 'absolute',
            right: '30px',
            top: '15px',
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle sx={{ px: '40px', py: '30px' }}>
          {/* <Typography id='modal-modal-title' variant='h4' component='h2' sx={{ textAlign: 'left' }}>
            Schedule a Demo
          </Typography> */}
        </DialogTitle>
        <DialogContent sx={{ px: '40px', py: '30px' }}>
          <Stack direction={'column'} alignItems='center' justifyContent='center'>
            <Stack spacing={2}>
              <Box>
                <Typography
                  id='modal-subtitle'
                  variant='body1'
                  sx={{ mb: 3, textAlign: 'left', fontWeight: 500 }}
                >
                  Please provide your information and we will contact you within 24 hours.
                </Typography>
              </Box>

              <Stack
                spacing={1}
                direction={!matches ? 'row' : 'column'}
                justifyContent='space-between'
                sx={{ width: '100%' }}
                gap='20px'
              >
                <TextFieldWithTitle
                  width='100%'
                  field={'firstName'}
                  label='First Name'
                  value={formData.firstName}
                  handleInput={(k, v) => handleChange(k, v)}
                />
                <TextFieldWithTitle
                  width='100%'
                  field={'lastName'}
                  label='Last Name'
                  value={formData.lastName}
                  handleInput={(k, v) => handleChange(k, v)}
                />
              </Stack>

              <Stack
                spacing={1}
                direction={!matches ? 'row' : 'column'}
                justifyContent='space-between'
                sx={{ width: '100%' }}
                gap='20px'
              >
                <TextFieldWithTitle
                  width={'100%'}
                  field={'email'}
                  label='Email'
                  value={formData.email}
                  handleInput={(k, v) => handleChange(k, v)}
                />
                <TextFieldWithTitle
                  width={'100%'}
                  field={'contact'}
                  label='Phone'
                  value={formData.contact}
                  handleInput={(k, v) => handleChange(k, v)}
                />
              </Stack>

              <Box
                sx={{
                  m: 1,
                  width: matches ? '95%' : '100%',
                  textAlign: 'left',
                }}
              >
                <FormControl sx={{ mt: 4, mb: 4 }}>
                  <FormLabel id='demo-radio-buttons-group-label'>
                    <Typography
                      variant='h6'
                      gutterBottom
                      sx={{ color: 'black' }}
                    >
                      Tell us about yourself
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby='demo-radio-buttons-group-label'
                    defaultValue='female'
                    name='radio-buttons-group'
                    onChange={(e) => handleChange('reason', e.target.value)}
                    sx={{
                      color: '#757575',
                      '& .MuiRadio-root.Mui-checked': {
                        color: 'primary.black',
                      },
                    }}
                  >
                    <FormControlLabel
                      value='I am a parent looking to make a yearbook'
                      control={<Radio size='small' />}
                      label='I am a parent looking to make a yearbook'
                    />
                    <FormControlLabel
                      value='I am a school staff looking to make yearbooks'
                      control={<Radio size='small' />}
                      label='I am a school staff looking to make yearbooks'
                    />
                    {/* <FormControlLabel
                      value='I am volunteer of a parent'
                      control={<Radio size='small' />}
                      label='I am volunteer of a parent'
                    /> */}
                  </RadioGroup>
                </FormControl>
              </Box>
            </Stack>
          </Stack>
          <Button
            variant='contained'
            color='primary'
            type='submit'
            sx={{
              m: 1,
              px: '40px',
              ':hover': {
                backgroundColor: 'rgba(54,45,45,1)',
              },
            }}
            onClick={handleSubmit}
            disabled={isDisabled()}
          >
            Submit
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
}
