import { Axios } from "./axios";

export const callPostAPI = async (data, url, options = {}) => {
  let result = await Axios.post(url, data, options);
  return result;
};

export const callGetAPI = async (url, options = {}) => {
  let result = await Axios.get(url, options);
  return result;
};
