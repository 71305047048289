import React, { useRef, useState } from 'react';
import {
  Button,
  Grid,
  Stack,
  Typography,
  Box,
  Container,
  Card,
  CardMedia,
  CardContent,
  TextField,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Alert,
  Portal,
  Snackbar,
} from '@mui/material';
import PhotoSharingAppBar from './photoSharingAppbar';
import BookADemoButton from './component/BookADemoButton';
import { callPostAPI } from '../../helpers/api';

export default function PhotoSharingCampHome() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const formRef = useRef(null);
  const scrollToForm = () => {
    formRef.current?.scrollIntoView({ behavior: 'smooth' }); // Step 4: Use the ref to scroll
  };
  return (
    <Box>
      <Container>
        <PhotoSharingAppBar />
        <Grid
          container
          direction={fullScreen ? 'column' : 'row'}
          alignContent={'center'}
          alignItems='center'
          sx={{ mt: 12, pb: 5 }}
        >
          <Grid item sx={{ width: fullScreen ? '80%' : '50%' }}>
            {fullScreen ? (
              <Typography variant='heroTitle' sx={{ textAlign: 'center', display: 'block' }}>
                Share photos the right way
              </Typography>
            ) : (
              <Box>
                <Typography variant='heroTitle'>Share photos </Typography>
                <Typography variant='heroTitle'>the right way </Typography>
              </Box>
            )}
            <Typography
              variant='heroSubTitleLight'
              sx={{
                mt: 2,
                maxWidth: fullScreen ? '1000px' : '450px',
                display: 'block',
                textAlign: fullScreen ? 'center' : 'left',
              }}
            >
              Provide families a personalized experience and preserve those joyous moments forever
            </Typography>
            <Stack
              direction={'row'}
              gap={2}
              sx={{ mt: 6, justifyContent: fullScreen ? 'center' : 'flex-start' }}
            >
              <BookADemoButton />
            </Stack>
            <Box sx={{ mt: 6 }}></Box>
          </Grid>
          <Grid
            item
            alignItems={'center'}
            justifyContent={'center'}
            sx={{ width: fullScreen ? '80%' : '50%', height: '540px' }}
          >
            <Box
              sx={{
                display: 'block',
                textAlign: 'center', // Center the image inside the Box
                alignItems: 'center',
                height: '100%',
              }}
            >
              <img
                src='/photoSharingCampHero.png'
                style={{ maxWidth: '100%', maxHeight: '100%' }}
                alt=''
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Grid container sx={{ bgcolor: 'rgb(245,245,247)', py: 5 }}>
        <Container>
          <Grid item>
            <ValueProposition scrollToForm={scrollToForm} />
          </Grid>
        </Container>{' '}
      </Grid>
      <Grid container sx={{ bgcolor: 'white', mt: 12 }}>
        <Container>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant='sectionTitle'>What do I have to do?</Typography>
          </Box>
          <Grid container sx={{ justifyContent: 'center' }}>
            <Grid item sx={{ py: 5 }}>
              <WhatDoIHaveToDo />
            </Grid>
          </Grid>
          <Grid item>
            <Box sx={{ mt: 9, textAlign: 'center' }}>
              <Typography variant='sectionTitle'>Contact Us</Typography>
              <ContactForm formRef={formRef} />
            </Box>
          </Grid>
        </Container>
      </Grid>
    </Box>
  );
}

const ContactForm = ({ formRef }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [orgName, setOrgName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [alert, setAlert] = useState({ message: '', type: 'info' });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const nameArr = name.split(' ');
    setIsLoading(true);
    try {
      await callPostAPI(
        {
          email: email,
          firstName: nameArr[0] ?? '',
          lastName: nameArr[1] ?? '',
          organisationName: orgName,
          phoneNumber: phoneNumber,
          reason: 'one',
          capacity: '1-9',
          type: 'PHOTOSHARING',
        },
        '/demo/request',
      );
      setIsLoading(false);
      setAlert({ message: 'Your request sent successfully.', type: 'success' });
      resetUI();
    } catch (error) {
      console.log('Error submitting form:', error);
      setIsLoading(false);
      setAlert({ message: 'Something gone wrong!!', type: 'error' });
    }
  };

  const handleCloseErrorPopup = () => {
    setAlert({ message: '', type: 'info' });
  };

  const resetUI = () => {
    setEmail('');
    setName('');
    setOrgName('');
    setPhoneNumber('');
  };

  return (
    <Box
      component='form'
      ref={formRef}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        maxWidth: '450px',
        margin: 'auto',
        mt: 3,
        p: 3,
        // boxShadow: 3,
        borderRadius: 2,
      }}
      noValidate
      autoComplete='off'
    >
      <TextField
        label='Your name'
        variant='outlined'
        value={name}
        fullWidth
        onChange={(e) => setName(e.target.value)}
      />

      <TextField
        label='Camp name'
        variant='outlined'
        value={orgName}
        fullWidth
        onChange={(e) => setOrgName(e.target.value)}
      />

      <TextField
        label='Email'
        type='email'
        variant='outlined'
        value={email}
        fullWidth
        onChange={(e) => setEmail(e.target.value)}
      />

      <TextField
        label='Phone Number'
        type='tel'
        variant='outlined'
        value={phoneNumber}
        fullWidth
        onChange={(e) => setPhoneNumber(e.target.value)}
      />

      <Button
        type='submit'
        variant='contained'
        color='primary'
        fullWidth
        sx={{ mt: 4 }}
        onClick={handleSubmit}
      >
        {isLoading ? <CircularProgress size={20} color={'inherit'} /> : 'Submit'}
      </Button>
      <Portal>
        <Snackbar
          open={!!alert.message}
          autoHideDuration={6000}
          onClose={() => {
            handleCloseErrorPopup();
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert onClose={handleCloseErrorPopup} severity={alert.type} sx={{ width: '100%' }}>
            {alert.message}
          </Alert>
        </Snackbar>
      </Portal>
    </Box>
  );
};

const WhatDoIHaveToDo = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const data = [
    {
      img: '/shareRoster.jpeg',
      title: 'Share your camp roster',
      text: 'Share your camp roster with us so we can securely verify parents when they access photos on our website.',
    },
    {
      img: '/uploadToGoogle.jpeg',
      title: 'Upload photos',
      text: 'Upload your photos to Google Drive to get started. We’ll take care of the rest by removing duplicates and organizing the photos for you.',
    },
    {
      img: '/allDone.jpeg',
      title: `You're Done!`,
      text: `And that's it! Parents will now enjoy a richer, more personalized experience, effortlessly accessing and cherishing their child's camp memories.`,
    },
  ];

  return (
    <Stack sx={{ mt: 4, gap: 3 }} direction={fullScreen ? 'column' : 'row'}>
      {data.map((item, index) => (
        <Card
          sx={{ maxWidth: fullScreen ? 400 : 345, pb: 3, border: '1px solid rgb(225,225,225)' }}
          elevation={0}
        >
          <CardMedia sx={{ height: 180 }} image={item.img} title='green iguana' />

          <CardContent>
            <Typography gutterBottom variant='h5' component='div' sx={{ mt: 2 }}>
              {item.title}
            </Typography>
            <Typography variant='body1' color='text.secondary'>
              {item.text}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Stack>
  );
};

// const ValueProposition = () => {
//   const data = [
//     {
//       title: 'Filter your child photos',
//       text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
//     },
//     {
//       title: 'Easy prompt based image search',
//       text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi. Praesent vel urna ut felis luctus facilisis.',
//     },
//     {
//       title: 'Free digital memory book',
//       text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.',
//     },
//     {
//       title: 'Free storage for 1 year',
//       text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.',
//     },
//   ];

//   return (
//     <Box display={'flex'} flexWrap={'wrap'} gap={'10px'}>
//       {data.map((item, index) => (
//         <Box
//           key={index}
//           sx={{
//             alignItems: 'center',
//             bgcolor: 'brown',
//             ml: index % 2 === 0 ? '10px' : 0,
//             mr: index % 2 === 0 ? 0 : '10px',
//             width: 'calc(50vw - 15px)',
//             height: 'calc(50vw - 25px)',
//           }}
//         ></Box>
//       ))}
//     </Box>
//   );
// };

const ValueProposition = ({ scrollToForm }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const data = [
    {
      title: 'Personalized photo sharing',
      text: `Help your camp families save time and avoid frustration with our advanced photo filtering feature. Parents can effortlessly find their child's photos without having to sift through thousands of images, making their experience smoother and more enjoyable.`,
      src: '/personalizedPhotoSharing.jpeg',
    },
    {
      title: 'Easy prompt based image search',
      text: `Effortlessly find the photos by simply entering a prompt. No need to manually tag your photos. Our smart search feature saves time and delivers a more seamless search experience.`,
      src: '/searchByPrompt.jpeg',
    },
    {
      title: 'Free personalized digital memory book',
      text: `Give your camp families a way to capture their child's unforgettable camp memories with a free personalized digital memory book. They can create a lasting keepsake of their child's camp experience to cherish forever.`,
      src: '/personalizedDigitalBook.jpeg',
    },
    {
      title: 'Free storage for 1 year',
      text: `Families enjoy free storage for 1 year and can automatically download their child's memories to Google Photos, iCloud, or Google Drive.`,
      src: '/downloadToCloud.jpeg',
    },
  ];

  return (
    <Box>
      {data.map((item, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            flexDirection: fullScreen ? 'column' : index % 2 === 0 ? 'row' : 'row-reverse',
            alignItems: 'center',
            mb: 5,
            gap: 10,
            my: fullScreen ? 18 : 9,
          }}
        >
          <Box sx={{ width: fullScreen ? '70%' : '50%' }}>
            <Typography variant='sectionTitle'>{item.title}</Typography>
            <Box sx={{ mt: 3 }}>
              <Typography variant='body'>{item.text}</Typography>
            </Box>
            <Box sx={{ mt: 3 }}>
              <BookADemoButton />
            </Box>
          </Box>
          <Box
            sx={{ width: fullScreen ? '70%' : '50%', display: 'flex', justifyContent: 'center' }}
          >
            {/* Placeholder for image */}
            <img
              style={{
                width: '100%',
                // height: '360px',
                backgroundColor: '#e0e0e0',
                borderRadius: '8px',
                border: 'solid 1px rgb(225,225,225)',
              }}
              src={item.src}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

/*
search photos - no need to manually tag
  facial recognition
  photo scrubbing and ranking
  free storage for 1 year from end of camp date
  free personalized digital memory book

*/

/*
  one click upload to social media
  search photos - no need to manually tag
  facial recognition
  photo scrubbing and ranking
  free storage for 1 year from end of camp date
  rev share on all photo books sold to parents
*/
