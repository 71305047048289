import React from "react";
import {
  AccessTimeOutlined,
  DateRangeOutlined,
  SchoolOutlined,
  ShoppingBagOutlined,
} from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";

const getIcon = (type, styleData) => {
  if (type === "date")
    return (
      <DateRangeOutlined
        sx={{
          color: "black",
        }}
      />
    );
  if (type === "time")
    return (
      <AccessTimeOutlined
        sx={{
          color: "black",
        }}
      />
    );
  if (type === "school")
    return (
      <SchoolOutlined
        sx={{
          color: "black",
        }}
      />
    );
  if (type === "bag")
    return (
      <ShoppingBagOutlined
        sx={{
          color: "black",
        }}
      />
    );
  return;
};

const ThemeTextField = (props) => {
  const { sx, styleData, icon, ...rest } = props;

  return (
    <TextField
      sx={{
        width: "100%",
        backgroundColor: styleData.color.inputBg,
        borderRadius: "10px",
        "& input": {
          "::-webkit-calendar-picker-indicator": {
            background: "none",
          },
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: 0,
            borderRadius: "10px",
          },
          "&.Mui-focused fieldset": {
            borderColor: styleData.color.main,
          },
        },
        ...sx,
      }}
      rows={3}
      required
      InputProps={{
        startAdornment: icon && (
          <InputAdornment position="start">
            {getIcon(icon, styleData)}
          </InputAdornment>
        ),
      }}
      onClick={(e) => {
        if (rest.type === "time" || rest.type === "date") e.target.showPicker();
      }}
      {...rest}
    />
  );
};

export default ThemeTextField;
