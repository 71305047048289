import React from "react";
import {
  Typography,
  Grid,
  useMediaQuery,
  Link,
  Box,
  Stack,
  ImageList,
  Button,
} from "@mui/material";
import ResponsiveAppBar from "../components/appBar";

export default function SelectRole() {
  return (
    <>
      <ResponsiveAppBar showLogin={false} />

      <Grid container sx={{ mt: "90px" }}>
        <Grid xs={1} md={3} item></Grid>
        <Grid xs={10} md={3} item sx={{ width: "100%" }}>
          <Box
            display={"flex"}
            justifyContent="center"
            alignItems={"center"}
            flexDirection="column"
            gap="40px"
            sx={{
              width: "300px",
              height: "300px",
              p: "30px",
              mx: "10px",
              borderRadius: "9px",
              boxShadow: "1",
              border: "solid 1px rgba(0,0,0,0.09)",
            }}
          >
            <img style={{ width: "120px", height: "120px" }}></img>
            <Link href={`${process.env.REACT_APP_PARENT_URL}/signin/parent`}>
              <Button variant="contained">Sign in as Parent</Button>
            </Link>
          </Box>
        </Grid>
        <Grid xs={0} md={3} item sx={{ width: "100%" }}>
          <Box
            display={"flex"}
            justifyContent="center"
            alignItems={"center"}
            flexDirection="column"
            gap="40px"
            sx={{
              width: "300px",
              height: "300px",
              p: "30px",
              mx: "10px",
              borderRadius: "9px",
              boxShadow: "1",
              border: "solid 1px rgba(0,0,0,0.09)",
            }}
          >
            <img style={{ width: "120px", height: "120px" }}></img>
            <Link href={`${process.env.REACT_APP_SCHOOL_URL}/signin/school`}>
              <Button variant="contained">Sign in as School Staff</Button>
            </Link>
          </Box>
        </Grid>
        <Grid xs={1} md={3} item></Grid>
      </Grid>
    </>
  );
}
