import React, { useState } from 'react';
import { Typography, ImageList, useMediaQuery, Button, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import RequestDialog from '../../components/RequestDialog';
import TextButtoWithChevron from '../../components/TextButtoWithChevron';
import App from '../../assets/app.webp';
import OtherAppProviders from '../../assets/other-app-providers.webp';
import GoogleDrive from '../../assets/google-drive.webp';

export default function WaysToUploadPhoto() {
  const theme = useTheme();
  const [showRequest, setShowRequest] = useState({
    status: false,
    title: '',
    text: '',
    type: '',
  });

  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const isSm = useMediaQuery(theme.breakpoints.down('md'));
  const isMd = useMediaQuery(theme.breakpoints.down('lg'));

  const numberOfCols = () => {
    if (isXs) {
      return { col: 1, gap: 20 };
    }
    if (isSm) {
      return { col: 1, gap: 20 };
    }
    if (isMd) {
      return { col: 3, gap: 20 };
    }
    return { col: 3, gap: 30 };
  };

  const handleClick = (type) => {
    console.log(type);
    //call api
  };

  const data = [
    {
      url: App,
      title: 'MyVity App',
      desc: ' Download MyVity app and take photos. Photos are automatically organized into albums and saved in the cloud at no additional cost. You can also bulk download photos at anytime.',
      chip: { text: 'MOST CONVENIENT', bg: 'rgba(255,80,52,1)' },
      button: (
        <Button
          variant='contained'
          href={`${process.env.REACT_APP_SCHOOL_URL}/signup/school`}
          sx={{
            ml: '20px',
            mt: '50px',
            mb: '30px',
            bgcolor: 'black',
            position: 'absolute',
            bottom: '0px',
            ':hover': {
              bgcolor: 'black',
              opacity: 1,
            },
          }}
        >
          SIGNUP
        </Button>
      ),
      buttons: (
        <TextButtoWithChevron
          handleClick={(e) =>
            setShowRequest({
              status: true,
              type: 'app-demo',
              title: 'App Demo',
              text: 'Please enter your email address below and we will reach out to you to schedule a demo at your convenience.',
            })
          }
          title='Request Demo'
        />
      ),
    },

    {
      url: OtherAppProviders,
      title: 'Transfer Photos',
      desc: 'Are your school photos with other child care app providers? No worries. Use our free automated solution to bulk download photos with just a button click.',
      chip: { text: 'MOST WANTED', bg: 'darkgreen' },
      button: (
        <Button
          variant='contained'
          onClick={(e) =>
            setShowRequest({
              status: true,
              type: 'transfer',
              title: 'Transfer Photos',
              text: 'Please enter your email address below and we will reach out to you to help transfer copies of your school photos from other child care providers.',
            })
          }
          sx={{
            ml: '20px',
            mt: '50px',
            mb: '30px',
            position: 'absolute',
            bottom: '0px',
            bgcolor: 'black',
            ':hover': {
              bgcolor: 'black',
              opacity: 1,
            },
          }}
        >
          START TRANSFER
        </Button>
      ),
      buttons: (
        <TextButtoWithChevron
          handleClick={(e) =>
            setShowRequest({
              status: true,
              type: 'transfer-photos',
              title: 'Transfer Photos',
              text: 'Please enter your email address below and we will reach out to you to help transfer copies of your school photos from other child care providers.',
            })
          }
          title='Contact us to get started'
        />
      ),
    },
    // {
    //   url: process.env.PUBLIC_URL + "email.png",
    //   title1: "Email",
    //   title2: "",
    //   desc: "Parents or school can simply email the photos to us at anytime during the school year. We will organize the photos into albums and include the precious growing up memories in the memory book.",
    //   sticker: { text: "Easy for parents", bg: "rgba(173,216,255,0.9)" },
    //   buttons: (
    //     <TextButtoWithChevron
    //       handleClick={(e) => handleClick()}
    //       title="Signup school"
    //     />
    //   ),
    // },
    {
      url: GoogleDrive,
      title: 'Google Drive',
      desc: 'Get a dedicated and secure Google Drive folder to upload growing up memories at home and at school. We will organize the photos into albums for you at no additional cost.',
      chip: {
        text: 'BEST IF PHOTOS ON DESKTOP',
        bg: 'rgba(152,115,245,1)',
      },
      button: (
        <Button
          variant='contained'
          href={`${process.env.REACT_APP_SCHOOL_URL}/signup/school`}
          sx={{
            ml: '20px',
            mt: '50px',
            mb: '30px',
            bgcolor: 'black',
            position: 'absolute',
            bottom: '0px',
            ':hover': {
              bgcolor: 'black',
              opacity: 1,
            },
          }}
        >
          SIGNUP
        </Button>
      ),
      buttons: <TextButtoWithChevron handleClick={(e) => handleClick()} title='Signup school' />,
    },
  ];
  return (
    <Box
      display='flex'
      alignItems='center'
      flexDirection='column'
      sx={{
        backgroundImage: 'linear-gradient(260.92deg,#f5f9ff 12.21%,rgba(237,241,247,.2) 78.08%)',
        py: '80px',
      }}
    >
      <Box sx={{ width: { sm: '90%', xs: '90%', lg: '81%' } }}>
        <Typography
          variant={'h3'}
          component='span'
          fontWeight='400'
          sx={{ color: 'rgba(77,71,97,1)' }}
        >
          What do you need to do to get the Yearbook ?{' '}
        </Typography>
        <Typography variant={'h3'} fontWeight='900' component='span'>
          Just take photos and share with us.
        </Typography>
        <Typography variant={'body1'} sx={{ mt: '20px', color: '#757575', fontSize: '18px' }}>
          We have created custom solutions to make it easy for you to capture, organize and preserve
          growing up memories. You can choose one or multiple solutions to upload photos and create
          your personalized memory book.
        </Typography>
        <ImageList
          sx={{
            width: '100%',
            mt: '80px',
          }}
          cols={numberOfCols().col}
          gap={numberOfCols().gap}
        >
          <HIWCard data={data[0]} />
          <HIWCard data={data[1]} />
          <HIWCard data={data[2]} />
          {/* <HIWCard data={data[3]} /> */}
        </ImageList>
        <RequestDialog
          open={showRequest.status}
          handleClose={(e) => setShowRequest(false)}
          type={showRequest.type}
          title={showRequest.title}
          text={showRequest.text}
        />
      </Box>
    </Box>
  );
}

const HIWCard = ({ data }) => {
  return (
    <Box
      sx={{
        width: '100%',
        border: 'solid 1px rgba(0,0,0,0.09)',
        borderRadius: '8px',
        bgcolor: 'white',
        position: 'relative',
        pb: '140px',
      }}
    >
      <img
        src={data.url}
        alt=''
        style={{
          width: '100%',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        }}
      />
      <Box sx={{ mx: '20px' }}>
        <Typography variant='h4' fontWeight='900' sx={{ mt: '40px' }}>
          {data.title}
        </Typography>

        <Box sx={{ mt: '20px' }}>
          <Typography
            variant='caption'
            sx={{
              bgcolor: data.chip.bg,
              borderRadius: '16px',
              fontSize: '12px',
              px: '12px',
              py: '4px',
              color: 'white',
              fontWeight: 900,
            }}
          >
            {data.chip.text}
          </Typography>
        </Box>
        <Typography variant='body1' sx={{ mt: '40px' }}>
          {data.desc}
        </Typography>
      </Box>

      {data.button}
    </Box>
  );
};
