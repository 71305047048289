import { Box } from '@mui/material';
import React from 'react';

const MediaPreview = ({ url, type, objectFit }) => {
  const photoPreview = (
    <Box
      component='img'
      src={url}
      sx={{
        width: '100%',
        height: '100%',
        margin: 'auto',
        borderRadius: '10px',
        objectFit,
      }}
    />
  );

  const videoPreview = (
    <Box
      component='video'
      sx={{
        height: '100%',
        width: '100%',
        margin: 'auto',
        borderRadius: '10px',
        objectFit,
      }}
      controls
    >
      <source src={url} type='video/mp4'></source>
      Your browser does not support the video tag.
    </Box>
  );

  return type === 0 ? photoPreview : videoPreview;
};

export default MediaPreview;
