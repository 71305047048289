import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, InputBase, Paper, Typography } from '@mui/material';
import MyVityLogo from '../components/MyVityLogo';
import Spinner from '../components/Spinner';
import { callGetAPI } from '../helpers/api';

const BuildingKidzSchools = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [schools, setSchools] = useState([]);
    const [cityInput, setCityInput] = useState('');

    const getBuldingKidzSchools = async () => {
        try {
            setIsLoading(true);
            let response = await callGetAPI("/schools/bulding-kidz");

            if (response?.success) {
                setSchools(response.schools);
            }
            setIsLoading(false);
        } catch (error) {
            console.log("Error:", error);
            setIsLoading(false);
        }
    }

    const getSchoolsList = () => {
        if (cityInput === undefined) {
            return [];
        }
        if (cityInput?.length < 2) {
            return [];
        }

        return schools.filter((school) => school.address.city.toLowerCase().includes(cityInput.toLowerCase()));
    };

    const handleSelectSchool = (_, selectedValue) => {
        window.location.href = `https://products.makemyvity.com/school-memory-book-purchase-form/${selectedValue.schoolCode}`;
    }

    useEffect(() => {
        getBuldingKidzSchools();
    }, []);

    return (
        <Box sx={{ height: '100vh' }}>
            {isLoading ? (<Spinner open={true} />) : (
                <Box sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    p: '30px'
                }}>
                    <Box sx={{
                        mt: { xs: '60px', sm: '100px' },
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Box sx={{ height: { xs: '40px', sm: '40px', cursor: 'pointer' } }}>
                            <MyVityLogo color='black' />
                        </Box>
                        <Box sx={{ mt: '50px' }}>
                            <Typography variant='h5' sx={{ textAlign: 'center', mb: "2rem" }}>Enter city of your school</Typography>
                            <Autocomplete
                                disablePortal
                                options={getSchoolsList()}
                                getOptionLabel={(option) => `${option?.info?.name || ''} - ${option?.address?.city || ''}`}
                                onChange={handleSelectSchool}
                                sx={{
                                    mt: '10px',
                                    width: { xs: '280px', sm: '400px' },
                                    height: '60px',
                                    pt: '4px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                                renderInput={(params) => {
                                    return (
                                        <Paper
                                            component='form'
                                            elevation={0}
                                            sx={{
                                                p: '2px 4px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '100%',
                                                maxWidth: '400px',
                                                border: 'solid 1px rgba(0,0,0,0.36)',
                                                borderLeft: 'solid 6px #0065CC',
                                            }}
                                        >
                                            <InputBase
                                                ref={params.InputProps.ref}
                                                inputProps={params.inputProps}
                                                sx={{
                                                    ml: 1,
                                                    flex: 1,
                                                }}
                                                placeholder="Enter school city"
                                                onChange={(e) => setCityInput(e.target.value)}
                                            />
                                        </Paper>
                                    );
                                }}
                            ></Autocomplete>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    )
}

export default BuildingKidzSchools;