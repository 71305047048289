import React from 'react';
import { Button } from '@mui/material';

export default function BookADemoButton() {


    const handleClick = () => {
        if (window.Calendly) {
            window.Calendly.initPopupWidget({
                url: 'https://calendly.com/makemyvity-support/demo-with-myvity?hide_gdpr_banner=1'
            });
        } else {
            console.error('Calendly is not loaded');
        }
    };

    return (
        <Button variant="contained" onClick={handleClick}>
            Book a demo
        </Button>
    );
}