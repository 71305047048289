import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  ImageList,
  ImageListItem,
  Typography,
  useMediaQuery,
} from "@mui/material";

import FlyerCanvas from "./FlyerCanvas/FlyerCanvas";
import FlyerFormModal from "./FlyerForm/FlyerForm";
import { CheckCircle, Circle } from "@mui/icons-material";
import ThemeButton from "./components/ThemeButton";
import ThankYouModal from "./ThankYouModal/ThankYouModal";

import { CloudFuncAxios } from "../../helpers/axios";
import { base64toBlob } from "../../utils/imageFunctions";
import { createFieldsData, createFlyerData, createStyleData } from "./data";

const Flyer = () => {
  let currentCanvasRef = useRef(null);
  const navigate = useNavigate();

  const [currentEventType, setCurrentEventType] = useState("halloween");
  const [initialFormLoad, setInitialFormLoad] = useState(true);
  const [currentFlyer, setCurrentFlyer] = useState("");
  const [styleData, setStyleData] = useState(createStyleData());
  const [flyerData, setFlyerData] = useState(createFlyerData("halloween"));
  const [fieldsData, setFieldsData] = useState(createFieldsData());
  const [openForm, setOpenForm] = useState(false);
  const [openThankyouModal, setThankyouModal] = useState(false);
  const [isFontLoaded, setFontLoaded] = useState(false);
  const [isSubmitting, setIsSumitting] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(min-width:601px) and (max-width:1024px)");

  // Calculate the number of columns based on screen size
  const getCols = () => {
    if (isMobile) return 1;
    if (isTablet) return 2;
    return 3; // Default to 3 columns for desktop
  };

  const changeFlyerData = (data) => {
    setFlyerData(data);
  };

  const changeFieldsData = (data) => {
    setFieldsData(data);
  };

  const changeCurrentFlyer = (flyerId) => {
    setCurrentFlyer(flyerId);
  };

  const onSubmit = async () => {
    setIsSumitting(true);
    // Form
    const formData = new FormData();
    const type = searchParams.get("type");
    const uuid = searchParams.get("uuid");
    formData.set("cusProId", uuid);
    formData.set("eventId", type);
    formData.set("flyerId", currentFlyer);

    const base64String = currentCanvasRef
      .captureImage()
      .replace(/^data:image\/[a-z]+;base64,/, "");
    const imageBlob = base64toBlob(base64String);
    formData.append("flyer", imageBlob);

    try {
      await CloudFuncAxios.post("/emailCampaigns", formData);
    } catch (error) {
      console.log("Submit error: ", error);
    }
    setIsSumitting(false);
    setThankyouModal(true);
  };

  const handleFormModal = () => {
    setInitialFormLoad(false);
    setOpenForm((prev) => !prev);
  };

  // Load all font files
  useEffect(() => {
    const loadFont = async (fontFamily, idx) => {
      let newFont = new FontFace(
        fontFamily.name,
        `url(assets/fonts/${fontFamily.file})`
      );
      let font = await newFont.load();
      document.fonts.add(font);
      return idx;
    };
    styleData.loadFontFamiles.forEach((family, idx) => {
      loadFont(family, idx).then((idx) => {
        if (idx === styleData.loadFontFamiles.length - 1) {
          setFontLoaded(true);
        }
      });
    });
  }, [styleData]);

  useEffect(() => {
    const addView = async () => {
      if (searchParams.get("uuid") && searchParams.get("type")) {
        const type = searchParams.get("type");
        const uuid = searchParams.get("uuid");
        setCurrentEventType(type);
        // Save user view
        await CloudFuncAxios.post("/addUserFlyerInteraction", {
          uuid,
          flyerType: type,
        });
      } else {
        // Redirect
        navigate("/");
      }
    };
    addView();
  }, [searchParams]);

  return (
    <Box
      sx={{
        overflow: "hidden",
      }}
    >
      {/* Submit loader */}
      <Backdrop
        open={isSubmitting}
        sx={{
          zIndex: 999999,
        }}
      >
        <CircularProgress
          size={68}
          sx={{
            color: styleData.color.main,
          }}
        />
      </Backdrop>
      {/* Background */}
      <Box
        sx={{
          position: "fixed",
          zIndex: -1,
          height: "100%",
          width: "100%",
        }}
      >
        <img
          src={styleData.backgroundImg}
          height={"100%"}
          width={"100%"}
          style={{
            objectFit: "cover",
            opacity: 0.15,
          }}
          alt="background"
        />
        <Box
          sx={{
            position: "absolute",
            zIndex: -2,
            top: 0,
            left: 0,
            backgroundColor: styleData.color.light,
            height: "100%",
            width: "100%",
          }}
        ></Box>
      </Box>
      {/* Main Content */}
      <Container
        maxWidth={"lg"}
        sx={{
          mb: "100px",
        }}
      >
        {/* Head Title */}
        <Typography
          textAlign={"center"}
          mt={"20px"}
          sx={{
            fontSize: "80px",
            fontFamily: styleData.titleFontFamily,
            color: styleData.color.main,
            textShadow: `3px 3px 0px ${styleData.color.dark}`,
            lineHeight: "78px",
            letterSpacing: "1px",
          }}
        >
          {styleData.title}
        </Typography>
        {/* Instruction */}
        <Typography
          variant="h6"
          textAlign={"center"}
          mt={"30px"}
          fontWeight={500}
          sx={{
            color: "#333",
            letterSpacing: "1px",
          }}
        >
          {styleData.instruction}
        </Typography>

        {/* Flyers */}
        {isFontLoaded && (
          <ImageList cols={getCols()}>
            {Object.entries(flyerData).map(([flyerId, flyer]) => {
              return (
                <ImageListItem
                  key={flyerId}
                  sx={{
                    overflow: "hidden",
                    position: "relative",
                    m: "12px",
                    cursor: "pointer",
                    borderRadius: "20px",
                  }}
                  onClick={() => {
                    changeCurrentFlyer(flyerId);
                    // Open only at first start
                    initialFormLoad && handleFormModal();
                  }}
                >
                  {/* Checkbox on selected */}
                  <Box
                    sx={{
                      position: "absolute",
                      top: "10px",
                      right: "15px",
                      zIndex: 1,
                    }}
                  >
                    {currentFlyer === flyerId ? (
                      <CheckCircle
                        sx={{
                          fontSize: "42px",
                          color: "white",
                          filter: `drop-shadow(3px 3px 0px ${styleData.color.main})`,
                        }}
                      />
                    ) : (
                      <Circle
                        sx={{
                          fontSize: "42px",
                          color: "#FFFFFF66",
                        }}
                      />
                    )}
                  </Box>
                  {/* canvas */}
                  <FlyerCanvas
                    ref={(ref) => {
                      if (currentFlyer === flyerId) {
                        currentCanvasRef = ref;
                      }
                    }}
                    configArray={flyer}
                    id={flyerId}
                    styleData={styleData}
                  />
                </ImageListItem>
              );
            })}
          </ImageList>
        )}
      </Container>
      {/* Form */}
      <FlyerFormModal
        open={openForm}
        handleFormClose={handleFormModal}
        styleData={styleData}
        flyerData={flyerData}
        fieldsData={fieldsData}
        changeFlyerData={changeFlyerData}
        changeFieldsData={changeFieldsData}
        handleFormModal={handleFormModal}
      />
      {/* Thankyou Modal */}
      <ThankYouModal
        open={openThankyouModal}
        handleClose={() => {
          setThankyouModal(false);
        }}
        styleData={styleData}
      />
      {/* Edit, Submit */}
      <Box
        sx={{
          position: "fixed",
          zIndex: 1,
          bottom: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          width: "100%",
          backgroundColor: `${styleData.color.light}`,
          py: 2,
          boxShadow: "0px -25px 20px -20px rgba(0,0,0,0.15)",
        }}
      >
        <ThemeButton
          text={"Edit flyer"}
          onClick={handleFormModal}
          styleData={styleData}
          variant="outlined"
        />
        <ThemeButton
          text={"Get your flyer"}
          onClick={onSubmit}
          styleData={styleData}
          variant="contained"
          disabled={!currentFlyer}
        />
      </Box>
    </Box>
  );
};

export default Flyer;
