import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Box, CircularProgress } from "@mui/material";

import { imageLoadPromise } from "../../../utils/imageFunctions";

const calculateFontSize = (ctx, text, fontStyle, maxTextWidth) => {
  ctx.font = fontStyle;
  const textRef = ctx.measureText(text);
  const textWidth = textRef.width;
  // If font fit to max width -> return
  if (textWidth < maxTextWidth) {
    return fontStyle;
  }

  // Continue if not
  const newFont = fontStyle.split("px ");
  const newFontSize = newFont[0] - 2;
  const fontFamily = newFont[1];
  const newFontStyle = `${newFontSize}px ${fontFamily}`;
  calculateFontSize(ctx, text, newFontStyle, maxTextWidth);
};

const FlyerCanvas = forwardRef(({ configArray, id, styleData }, ref) => {
  const canvasRef = useRef(null);
  const imageRef = useRef(null);
  const [canvasLoaded, setCanvasLoaded] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const loadImage = ({ imageObj, config, canvas, ctx }) => {
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;
    const imageWidth = canvasWidth * config.boundingBoxCords.width;
    const imageHeight = canvasHeight * config.boundingBoxCords.height;

    // Draw the image on the canvas
    ctx.drawImage(
      imageObj,
      config.boundingBoxCords.x,
      config.boundingBoxCords.y,
      imageWidth,
      imageHeight
    );
  };

  const loadText = ({ config, canvas, ctx }) => {
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;

    const text = config.value || config.placeholder;

    // all config cords are in %
    // Text x cord
    const textX = canvasWidth * config.boundingBoxCords.x;
    // Text y cord
    const textY = canvasHeight * config.boundingBoxCords.y;
    // Max allowed text width
    const maxTextWidth = canvasWidth * config.boundingBoxCords.width;
    // Max allowed text height
    const maxTextHeight = canvasHeight * config.boundingBoxCords.height;

    ctx.textBaseline = "hanging";
    ctx.fillStyle = config.style.fillStyle;

    // Find font size that satisfy the maxTextWidth
    calculateFontSize(ctx, text, config.style.font, maxTextWidth);

    const textRef = ctx.measureText(text);
    const textWidth = textRef.width;
    const textHeight =
      textRef.actualBoundingBoxAscent + textRef.actualBoundingBoxDescent;

    // Find center cords for the text
    const centerX = textX + (maxTextWidth - textWidth) / 2;
    const centerY = textY + (maxTextHeight - textHeight) / 2;

    // Set text at the center of the box
    ctx.fillText(config.value || config.placeholder, centerX, centerY);
  };

  const captureImage = () => {
    const imageObj = imageRef.current;
    return imageObj.src;
  };

  useImperativeHandle(ref, () => ({
    captureImage,
  }));

  useEffect(() => {
    const loadImageWithCanvas = async () => {
      setImageLoaded(false);
      setCanvasLoaded(false);
      // Find and load image first
      const imageConfig = configArray.find(
        (configObj) => configObj.type === "image"
      );
      let imageObj = await imageLoadPromise(imageConfig.url);
      const canvas = document.getElementById(id);
      // Set height and width of canvas as image resolution
      canvas.width = imageObj.naturalWidth;
      canvas.height = imageObj.naturalHeight;
      const ctx = canvas.getContext("2d");
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
      ctx.clearRect(0, 0, canvasWidth, canvasHeight);
      loadImage({
        imageObj,
        config: imageConfig,
        canvas,
        ctx,
      });
      ctx.globalCompositeOperation = "source-over";

      // set texts on canvas
      configArray.forEach((renderConfig) => {
        if (
          renderConfig.type === "text" ||
          renderConfig.type === "date" ||
          renderConfig.type === "time"
        ) {
          // Load any text
          loadText({
            config: renderConfig,
            canvas,
            ctx,
          });
        }
      });
      setCanvasLoaded(true);

      // Set image src
      if (canvasRef.current && imageRef.current) {
        const canvas = canvasRef.current;
        const dataURL = canvas.toDataURL("image/png", 1.0);
        imageRef.current.src = dataURL;
        setImageLoaded(true);
      }
    };
    loadImageWithCanvas();
  }, [configArray, id]);

  return (
    <Box
      sx={{
        position: "relative",
      }}
      minHeight={260}
    >
      {!imageLoaded && (
        <Box
          sx={{
            position: "absolute",
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress
            size={68}
            sx={{
              color: styleData.color.main,
            }}
          />
        </Box>
      )}

      <img
        ref={imageRef}
        style={{
          height: "100%",
          width: "100%",
          visibility: imageLoaded ? "visible" : "hidden",
        }}
        alt={`flyer-${id}`}
      />

      <canvas
        id={id}
        ref={canvasRef}
        style={{
          display: "none",
        }}
      ></canvas>
    </Box>
  );
});

export default FlyerCanvas;
